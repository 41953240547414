import React from 'react';

import Paper from '@mui/material/Paper';
import VendorInvoices from '../../components/VendorInvoices';
import Title from '../../components/Title';

export default function InvoicesList() {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Title>Invoices</Title>
      <VendorInvoices />
    </Paper>
  );
}
