import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Title from "./Title";
import { auth } from "../firebase/client";
import useConversions from "../hooks/useConversions";
import useCampaigns from "../hooks/useCampaigns";

// Generate Order Data
function createData(
  id,
  date,
  name,
  shipTo,
  paymentMethod,
  productName,
  amount
) {
  return { id, date, name, shipTo, paymentMethod, productName, amount };
}

const rows = [
  createData(
    0,
    "16 Mar, 2019",
    "Spring Campaign",
    "Card-Linked Offer",
    "Mastercard",
    "Nike Air Force",
    312.44
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function AdminRecentSales() {
  const { currentUser } = auth;
  const { loading: loadingConversions, items: conversions } = useConversions({
    ...currentUser,
    role: 1,
  });
  const { loading: loadingCampaigns, items: campaigns } = useCampaigns({
    ...currentUser,
    role: 1,
  });

  if (loadingConversions || loadingCampaigns) {
    return <div />;
  }

  return (
    <>
      <Title>Recent Sales</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Campaign Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Customer Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>Campaign Type</TableCell>
            <TableCell sx={{ fontWeight: "bold" }} align="right">
              Sale Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conversions.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{moment(row.created_at).format("LLL")}</TableCell>
              <TableCell>
                {campaigns.filter((c) => c.uid === row.muid)[0].campaignName}
              </TableCell>
              <TableCell>{row.customer.default_address.name}</TableCell>
              <TableCell>{row.line_items.map((item) => item.name)}</TableCell>
              <TableCell>
                {campaigns.filter((c) => c.uid === row.muid)[0].offerType ===
                  0 && "Card Linked Offer"}
                {campaigns.filter((c) => c.uid === row.muid)[0].offerType ===
                  1 && "Online Shopping"}
                {campaigns.filter((c) => c.uid === row.muid)[0].offerType ===
                  2 && "CLO & Online Shopping"}
              </TableCell>
              <TableCell align="right">{`$${row.total_price}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
