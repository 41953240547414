import React, { useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { Box, Grid, Typography } from '@mui/material';
import Title from './Title';

const mapconfig = {
  center: {
    lat: 43.653225,
    lng: -79.383186,
  },
  city: '',
  zoom: 10,
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function CampaignReach({ campaign }) {
  return (
    <>
      <Title>Campaign Audience</Title>
      <Grid container spacing={3}>
        <Grid item mb={2} xs={3}>
          <Typography variant="body1" fontWeight="bold">
            Locations
          </Typography>
          {(campaign.location && Array.isArray(campaign.locations)) &&
            campaign.locations.map((location) => (
              <Typography variant="body1">{location.city}</Typography>
            ))}
        </Grid>
        <Grid item mb={2} xs={3}>
          <Typography variant="body1" fontWeight="bold">
            Age Range
          </Typography>
          <Typography variant="body1">
            {campaign.minAge} - {campaign.maxAge}
          </Typography>
        </Grid>
        <Grid item mb={2} xs={3}>
          <Typography variant="body1" fontWeight="bold">
            Gender
          </Typography>
          <Typography variant="body1">{campaign.gender}</Typography>
        </Grid>
        <Grid item mb={2} xs={3}>
          <Typography variant="body1" fontWeight="bold">
            Languages
          </Typography>
          {Array.isArray(campaign.langinclusions) && campaign.langinclusions.map((lang) => (
            <Typography variant="body1">{lang}</Typography>
          ))}
          <br />
        </Grid>
      </Grid>
      <Box height="500px" width="100%" borderRadius={5} overflow="hidden">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAJQJbyWiZqciw_M0aGwWCor6vH9IAFBFE',
          }}
          defaultCenter={mapconfig.center}
          defaultZoom={mapconfig.zoom}
          // eslint-disable-next-line no-shadow
          onGoogleApiLoaded={({ map, maps }) => {
            campaign.locations.forEach((location) => {
              const triangleCoords = location.poly;

              const bermudaTriangle = new maps.Polygon({
                paths: triangleCoords,
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
              });
              bermudaTriangle.setMap(map);
            });
          }}
        />
      </Box>
    </>
  );
}
