import { Button } from '@mui/material';
import React from 'react';
import useUser from '../hooks/useUser';

const styles = {
  instagram_card: {
    background: '#ffffff',
    border: '1px solid #f2f2f2',
    borderRadius: '3px',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
  },
  instagram_card_header: { padding: '20px', height: '40px' },
  instagram_card_user_image: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    verticalAlign: 'middle',
  },
  instagram_card_time: {
    float: 'right',
    width: '80px',
    paddingTop: '10px',
    textAlign: 'right',
    color: '#999',
  },
  instagram_card_user_name: {
    marginLeft: '20px',
    fontWeight: 'bold',
    color: '#262626',
  },
  instagram_card_content: { padding: '20px' },
  likes: { fontWeight: 'bold' },
  instagram_card_content_user: { fontWeight: 'bold', color: '#262626' },
  hashtag: { color: '#003569' },
  comments: { color: '#999' },
  user_comment: { color: '#003569' },
  instagram_card_footer: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  hr: {
    border: 'none',
    borderBottom: '1px solid #ccc',
    marginTop: '30px',
    marginBottom: '0px',
  },
  footer_action_icons: { fontSize: '16px', color: '#ccc' },
  comments_input: { border: 'none', marginLeft: '10px', width: '100%' },
  intagram_card_image: {
    overflow: 'hidden',
    height: '300px',
    width: '100%',
    marginTop: '35px',
  },
};

export default function InstagramCard({
  campaign,
  onSelect,
  type,
  formState,
  setFormState,
}) {
  const user = useUser();

  if (!user) {
    return '';
  }
  return (
    <div
      style={{
        ...styles.instagram_card,
        border: type === 1 ? `3px solid orange` : '1px solid #f2f2f2',
      }}
    >
      <div style={styles.instagram_card_header}>
        <img
          alt="img"
          src={user.photoURL}
          style={styles.instagram_card_user_image}
        />
        <a
          style={styles.instagram_card_user_name}
          href="https://www.instagram.com/"
        >
          {user.companyName}
        </a>
        <div style={styles.instagram_card_time}>58 min</div>
      </div>

      <div style={styles.intagram_card_image}>
        <img alt="img" src={campaign.campaignImage} height="600px" />
      </div>

      <div style={styles.instagram_card_content}>
        <p style={styles.likes}>156 Likes</p>
        <p>
          <a
            style={styles.instagram_card_content_user}
            href="https://www.instagram.com/"
          >
            {user.companyName}
          </a>{' '}
          <textarea
            style={{
              width: '100%',
              height: '200px',
              marginTop: '10px',
              border: '1px solid lightgray',
              borderRadius: '5px',
            }}
            value={formState.caption}
            onChange={(e) =>
              setFormState({
                ...formState,
                caption: e.target.value,
              })
            }
          />
          <a
            style={styles.hashtag}
            href="https://www.instagram.com/explore/tags/"
          >
            #promotion
          </a>
        </p>
        <p style={styles.comments}>view all 48 comments</p>
        <a style={styles.user_comment} href="https://www.instagram.com/">
          Zainab Nur
        </a>{' '}
        wowwwwww
        <br />
        <br />
        <a style={styles.user_comment} href="https://www.instagram.com/">
          Saad Salman
        </a>{' '}
        😍
        <br />
        <br />
        <a style={styles.user_comment} href="https://www.instagram.com/">
          Chris Jones
        </a>
        cool ❄️
        <br />
        <hr />
      </div>

      <div style={styles.instagram_card_footer}>
        <a style={styles.footer_action_icon} href="#">
          <i className="fa fa-heart-o" />
        </a>
        <input
          style={styles.comments_input}
          type="text"
          placeholder="Leave a comment..."
        />
        <a style={styles.footer_action_icons} href="#">
          <i className="fa fa-ellipsis-h" />
        </a>
      </div>
      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <Button
          onClick={() => onSelect(1)}
          sx={{ color: 'blue', fontWeight: 'bold' }}
        >
          Promote as Instagram Post
        </Button>
      </div>
    </div>
  );
}
