import {react,useEffect, useState} from 'react'
import {collection,query,where,onSnapshot} from 'firebase/firestore'
import { db } from '../firebase/client'

const useUmmrahLotteryUsers = () => {
    const [ummrahLotteryUsersList,setUmmrahUsersList] = useState([])
   
    useEffect(()=>{
        const vendorsRef = collection(db, 'users');
        const q = query(vendorsRef, where('isAppliedForUmrahLottery', '==', true));
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          const docs = [];
          querySnapshot.forEach(async (document) => {
            docs.push({
              ...document.data(),
              id: document.id,
            });
          });
    
        setUmmrahUsersList(docs)
        });
    
        return unsubscribe;
    },[])

    return {ummrahLotteryUsersList}
}

export default useUmmrahLotteryUsers