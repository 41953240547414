import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import OtpInput from 'react18-input-otp';
import { db } from '../../../firebase/client';
import useUser from '../../../hooks/useUser';

export default function VerifyAccount() {
  const navigate = useNavigate();
  const user = useUser();
  const [otp, setOTP] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { status: 'complete-profile' }, { merge: true });

      navigate('/register/complete-profile');
    } catch (error) {
      console.log(error);
    }
  };

  const resendSms = async () => {
    // set otp code

    try {
      const sms = await fetch('https://m1.jannahsfamilyandfriends.com/sms');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Verify Your Account
      </Typography>
      <Container maxWidth="xs">
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <OtpInput
            containerStyle={{
              width: '100%',
              justifyContent: 'space-between',
              marginTop: 20,
            }}
            value={otp}
            onChange={(d) => setOTP(d)}
            numInputs={6}
            inputStyle={{ width: 50, height: 50, fontSize: 20 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Verify Account
          </Button>
          <Box textAlign="center" mt={10}>
            Didn't receive any anything?{' '}
            <a href="#" onClick={resendSms}>
              Resend code
            </a>
          </Box>
        </Box>
      </Container>
    </>
  );
}
