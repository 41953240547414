/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Container from '@mui/material/Container';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import AccountInfoStep from '../../../components/AccountInfoStep';
import AddressInfoStep from '../../../components/AddressInfoStep';
import CompanyBrandInfoStep from '../../../components/CompanyBrandInfoStep';
import TermsOfAgreementStep from '../../../components/TermsOfAgreementStep';
import ReviewStep from '../../../components/AccountReviewStep';

import useUser from '../../../hooks/useUser';
import { auth, db } from '../../../firebase/client';
import { FastfoodOutlined } from '@mui/icons-material';

const steps = [
  'Profile',
  'Address',
  'Branding',
  'Terms Of Agreement',
  'Review Your Profile',
];

export default function CompleteProfile() {
  const user = useUser();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [initializedForm, setinitalizedForm] = React.useState(false);
  const [isAgreedToTermsAndConditions, setIsAgreedToTermsAndCOnditions] = useState(false)
  const [formState, setFormState] = React.useState({
    firstName: null,
    lastName: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    postal: null,
    province: null,
    country: null,
    photoURL: null,
    bannerURL: null,
    brandPrimaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    brandSecondaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    companyName: null,
    companyDescription: null,
    companyKeywords: null,
    privacyPolicyUrl: null,
    category: null,
    subcategory: null,
    email: auth.currentUser.email,
  });

  const handleNext = async () => {

    if (activeStep === 3 && !isAgreedToTermsAndConditions) {
      alert("Please read terms and conditions.")
      return
    }

    if (activeStep === 4) {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(
        userRef,
        { ...formState, status: 'completed' },
        { merge: true }
      );
      if (user.role === 3) {
        navigate('/influencer/home');
      } else {
        navigate('/vendor/home');
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step, formState, setFormState) {
    switch (step) {
      case 0:
        return (
          <AccountInfoStep
            formState={formState}
            setFormState={setFormState} />
        );
      case 1:
        return (
          <AddressInfoStep
            formState={formState}
            setFormState={setFormState} />
        );
      case 2:
        return (
          <CompanyBrandInfoStep
            formState={formState}
            setFormState={setFormState}
          />
        );
      case 3:
        return (
          <TermsOfAgreementStep
            formState={formState}
            setFormState={setFormState}
            callBack={() => {
              setIsAgreedToTermsAndCOnditions(!isAgreedToTermsAndConditions)
            }}
          />
        );
      case 4:
        return <ReviewStep
          formState={formState}
          setFormState={setFormState} />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Complete Your {user && user.role === 3 && 'Influencer '} Profile
      </Typography>
      <Container maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{
            my: { xs: 3, md: 6 },
            p: { xs: 2, md: 3 }
          }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
          >
            {steps.map((label) => (
              <Step key={label} >
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography variant="h5" gutterBottom>
                Thank you for your registration.
              </Typography>
              <Typography variant="subtitle1">
                Your account number is #2001539. We have emailed your
                registration confirmation, and will send you an update when your
                account has been approved.
              </Typography>
            </>
          ) : (
            <>
              {getStepContent(activeStep, formState, setFormState)}
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    mt: 3, ml: 1
                  }}
                >
                  {activeStep === steps.length - 1
                    ? 'Complete Registration'
                    : 'Next'}
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
