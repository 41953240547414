import { useState, useEffect } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { collection, getDocs, query, where } from 'firebase/firestore';
import Title from './Title';
import LoadingBar from './LoadingBar';
import useUser from '../hooks/useUser';
import usePixel from '../hooks/usePixel';
import { db } from '../firebase/client';

// Generate Order Data
function createData(id, date, name, email) {
  return { id, date, name, email };
}

const rows = [createData(0, '06/12/2001', 'nasir@gmail.com')];

export default function UserConversionEvents({ userId }) {
  const viewingUser = useUser(userId);
  const navigate = useNavigate();
  const [walletPoints, setWalletPoints] = useState([]);
  const [loadingPoints, setLoadingPoints] = useState(true);
  const { loading: loadingPixel, items: pixelItems } = usePixel(userId);

  useEffect(() => {
    async function getWalletPoints() {
      const orderIds = pixelItems.map((item) => item['order-id']);
      const conversionRef = collection(db, 'conversions');
      const points = [];

      for (const orderId of orderIds) {
        const conversionsRefQuery = query(
          conversionRef,
          where('order_number', '==', parseInt(orderId))
        );
        const querySnapshots = await getDocs(conversionsRefQuery);
        querySnapshots.forEach((snap) => {
          const snapData = snap.data();
          points.push(snapData);
        });
      }

      setWalletPoints(points);
    }
    if (!loadingPixel && loadingPoints) {
      getWalletPoints();
    }
  }, [loadingPixel, loadingPoints]);

  if (!viewingUser) {
    return <LoadingBar />;
  }

  console.log(walletPoints);
  return (
    <>
      <Title>Purchases</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Id</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Sale Amount
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Campaign</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {walletPoints.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {moment(row.created_at).format('LL @ hh:mm A')}
              </TableCell>
              <TableCell>{row.line_items.map((item) => item.name)}</TableCell>
              <TableCell>
                {row.line_items.map((item) => item.product_id)}
              </TableCell>
              <TableCell align="right">
                {`$${row.total_price}`} {row.currency}
              </TableCell>
              <TableCell>
                <Link to={`/vendor/campaigns/${row.mcid}`}>View Campaign</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
