import React, { useState } from 'react';

import CardIcon from '@mui/icons-material/Payment';
import BothIcon from '@mui/icons-material/Star';
import OnlineIcon from '@mui/icons-material/CardGiftcard';

import {
  Box,
  Button,
  Divider,
  Grid,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FileUpload from './FileUpload';
import InclusionsList from './InclusionsList';
import Title from './Title';

const RenderTypeSection = ({ offerType, handleTypeClick }) => (
  <>
    <Grid
      item
      xs={3}
      p={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Button
        variant={offerType === 0 ? 'contained' : 'outlined'}
        onClick={() => handleTypeClick(0)}
        sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
      >
        <CardIcon />
        <Typography variant="button" mt={1}>
          Card-linked Offer
        </Typography>
      </Button>
    </Grid>
    <Grid
      item
      xs={3}
      p={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Button
        variant={offerType === 1 ? 'contained' : 'outlined'}
        onClick={() => handleTypeClick(1)}
        sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
      >
        <OnlineIcon />
        <Typography variant="button" mt={1}>
          Online Offer
        </Typography>
      </Button>
    </Grid>
    <Grid
      item
      xs={3}
      p={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Button
        variant={offerType === 2 ? 'contained' : 'outlined'}
        onClick={() => handleTypeClick(2)}
        sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
      >
        <BothIcon />
        <Typography variant="button" mt={1}>
          Both
        </Typography>
      </Button>
    </Grid>
  </>
);

function calculateValue(value) {
  return 2 * value;
}

function RenderPointSection({ pointAmount, handlePointSlide }) {
  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      handlePointSlide(newValue);
    }
  };

  return (
    <Box sx={{ flexBasis: '75%' }}>
      <Typography id="non-linear-slider" textAlign="center" gutterBottom>
        {calculateValue(pointAmount).toLocaleString('en-US')} Points{' '}
        <strong>Per $1</strong>
      </Typography>
      <Slider
        value={pointAmount}
        min={5}
        step={1}
        max={10000}
        scale={calculateValue}
        onChange={handleChange}
        aria-labelledby="non-linear-slider"
      />
    </Box>
  );
}

export default function CampaignOfferStep({ formState, setFormState }) {
  return (
    <>
      <Title>Offer Details</Title>
      <Grid container spacing={1} px={3} mt={3} justifyContent="center">
        {/** Select offer type */}
        <Grid item xs={12} mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            1. Select Offer Type
          </Typography>
        </Grid>
        <RenderTypeSection
          offerType={formState.offerType}
          handleTypeClick={(type) => {
            setFormState({
              ...formState,
              offerType: type,
            });
          }}
        />
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12} mt={3} mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            2. Campaign Url
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <TextField
            label="https://www.mywebsite.com/"
            fullWidth
            value={formState.campaignUrl}
            onChange={(e) =>
              setFormState({
                ...formState,
                campaignUrl: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            2. Choose Offer Date
          </Typography>
        </Grid>
        <Grid item xs={12} mt={3} mb={3}>
          <Stack component="form" direction="row" noValidate spacing={3}>
            <TextField
              id="date"
              label="Offer Start date"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={formState.startDate}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  startDate: e.target.value,
                })
              }
            />
            <TextField
              id="date"
              label="Offer End date"
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              value={formState.endDate}
              onChange={(e) =>
                setFormState({
                  ...formState,
                  endDate: e.target.value,
                })
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12} mb={3} mt={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            3. Adjust Points
          </Typography>
        </Grid>
        <RenderPointSection
          pointAmount={formState.pointAmount}
          handlePointSlide={(amount) =>
            setFormState({
              ...formState,
              pointAmount: amount,
            })
          }
        />

        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12} mb={3} mt={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            4. Inclusions & Exclusions
          </Typography>
        </Grid>
        <InclusionsList formState={formState} setFormState={setFormState} />
        <Grid item mt={3} xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12} mb={3} mt={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            5. Campaign Banner
          </Typography>
        </Grid>
        <FileUpload
          setFileName={(file) =>
            setFormState({
              ...formState,
              campaignImage: `https://firebasestorage.googleapis.com/v0/b/m1-admin-2f3a8.appspot.com/o/${file}`,
            })
          }
          buttonTitle="Upload Campaign Banner"
        />
      </Grid>
    </>
  );
}
