import React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Title from "./Title";
import useUsers from "../hooks/useUsers";
import useConversions from "../hooks/useConversions";
import { auth } from "../firebase/client";

export default function AdminMetrics() {
  const { currentUser } = auth;
  const { loading: loadingVendors, items: vendors } = useUsers(2);
  const { loading: loadingUsers, items: users } = useUsers(4);
  const { loading: loadingConversions, items: conversions } = useConversions({
    ...currentUser,
    role: 1,
  });

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 130,
          }}
        >
          <Title>Total Revenue</Title>
          <Typography component="p" variant="h4">
            $
            {!loadingConversions &&
              conversions.reduce(
                (p, c) => parseFloat(p) + parseFloat(c.total_price),
                0.0
              )}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 130,
          }}
        >
          <Title>Total Profit</Title>
          <Typography component="p" variant="h4">
            $
            {!loadingConversions &&
              conversions.reduce(
                (p, c) => parseFloat(p) + parseFloat(c.total_price),
                0.0
              )}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 130,
          }}
        >
          <Title>Total Vendors</Title>
          <Typography component="p" variant="h4">
            {!loadingVendors && vendors.length}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 130,
          }}
        >
          <Title>Total Users</Title>
          <Typography component="p" variant="h4">
            {!loadingUsers && users.length}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
