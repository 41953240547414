import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import InStoreTransactions from '../../components/InStoreTransactions';
import OnlineTransactions from '../../components/OnlineTransactions';
import Title from '../../components/Title';

export default function Invoice() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Total Revenue</Title>
              <Typography component="p" variant="h4">
                $0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Platform Profit</Title>
              <Typography component="p" variant="h4">
                $0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Merchant Profit</Title>
              <Typography component="p" variant="h4">
                $0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Card Linked Purchases</Title>
              <Typography component="p" variant="h4">
                0
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Online Purchases</Title>
              <Typography component="p" variant="h4">
                0
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 130,
              }}
            >
              <Title>Invoice Due</Title>
              <Typography component="p" variant="h4">
                30 days
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <InStoreTransactions />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <OnlineTransactions />
        </Paper>
      </Grid>
    </Grid>
  );
}
