import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function VendorCampaigns({ items, url, showVendorName }) {
  const navigate = useNavigate();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
          {showVendorName && (
            <TableCell sx={{ fontWeight: 'bold' }}>Vendor ID</TableCell>
          )}
          <TableCell sx={{ fontWeight: 'bold' }}>Campaign Name</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Campaign Type</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Campaign Status</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="right">
            Point Amount
          </TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(items) && items.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{moment(row.created_at).format('LLL')}</TableCell>
            {showVendorName && (
              <TableCell>{row.uid.substr(0, 6).toUpperCase()}</TableCell>
            )}
            <TableCell>{row.campaignName}</TableCell>
            <TableCell>
              {row.offerType === 0 && 'Card Linked Offer'}
              {row.offerType === 1 && 'Online Offer'}
              {row.offerType === 2 && 'CLO and Online Offer'}
            </TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell align="right">{row.pointAmount} Points /$1</TableCell>
            <TableCell align="right">
              <Button
                onClick={() => navigate(`${url ? `${url}${row.id}` : row.id}`)}
              >
                View Performance
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
