import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Title from './Title';

import { auth, db } from '../firebase/client';

export default function InfluencerMetrics({ items, conversions }) {
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Earnings</Title>
          <Typography component="p" variant="h4">
            $
            {conversions.reduce(
              (prev, currValue) =>
                parseFloat(prev) + parseFloat(currValue.total_price),
              0
            )}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Conversions</Title>
          <Typography component="p" variant="h4">
            {conversions.length}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Clicks</Title>
          <Typography component="p" variant="h4">
            {items.reduce(
              (prev, currValue) =>
                parseFloat(prev) + parseFloat(currValue.clicks),
              0
            )}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Promotions</Title>
          <Typography component="p" variant="h4">
            {items.length}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
