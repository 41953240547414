export default [
  {
    name: 'Home',
    path: 'home',
    icon: '',
  },
  // {
  //   name: 'Analytics',
  //   path: 'analytics',
  //   icon: '',
  // },
  {
    name: 'Campaigns',
    path: 'campaigns',
    icon: '',
  },
  {
    name: 'Invoices',
    path: 'invoices',
    icon: '',
  },
  {
    name: 'Account',
    path: 'account',
    icon: '',
  },
];
