import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function AddressInfoStep({ formState, setFormState }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          value={formState.address1}
          onChange={(e) =>
            setFormState({
              ...formState,
              address1: e.target.value,
            })
          }
          required
          id="address1"
          name="address1"
          label="Address line 1"
          fullWidth
          autoComplete="shipping address-line1"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.address2}
          onChange={(e) =>
            setFormState({
              ...formState,
              address2: e.target.value,
            })
          }
          id="address2"
          name="address2"
          label="Address line 2"
          fullWidth
          autoComplete="shipping address-line2"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.city}
          onChange={(e) =>
            setFormState({
              ...formState,
              city: e.target.value,
            })
          }
          required
          id="city"
          name="city"
          label="City"
          fullWidth
          autoComplete="shipping address-level2"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.province}
          onChange={(e) =>
            setFormState({
              ...formState,
              province: e.target.value,
            })
          }
          id="state"
          name="state"
          label="State/Province/Region"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.postal}
          onChange={(e) =>
            setFormState({
              ...formState,
              postal: e.target.value,
            })
          }
          required
          id="zip"
          name="zip"
          label="Zip / Postal code"
          fullWidth
          autoComplete="shipping postal-code"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.country}
          onChange={(e) =>
            setFormState({
              ...formState,
              country: e.target.value,
            })
          }
          required
          id="country"
          name="country"
          label="Country"
          fullWidth
          autoComplete="shipping country"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox color="secondary" name="saveAddress" value="yes" />
          }
          label="Use this address for payment details"
        />
      </Grid>
    </Grid>
  );
}
