import * as React from 'react';
import { SketchPicker } from 'react-color';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';

import LoadingBar from './LoadingBar';

import FileUpload from './FileUpload';
import useUser from '../hooks/useUser';

export default function CompanyBrandInfoStep({ formState, setFormState }) {
  const user = useUser();
  const handleChangeBrandColor = (color, type) => {
    if (type === 'primary') {
      setFormState({
        ...formState,
        brandPrimaryColor: color.rgb,
      });
    } else {
      setFormState({
        ...formState,
        brandSecondaryColor: color.rgb,
      });
    }
  };

  const handleCategoryChange = (event) => {
    setFormState({
      ...formState,
      category: event.target.value,
    });
  };

  const handleSubCategoryChange = (event) => {
    setFormState({
      ...formState,
      subcategory: event.target.value,
    });
  };

  if (!user) {
    return <LoadingBar />;
  }
  return (
    <Grid
      container
      spacing={3}

    >
      <Grid item xs={12}>
        <TextField
          value={formState.companyName}
          onChange={(e) =>
            setFormState({
              ...formState,
              companyName: e.target.value,
            })
          }
          required
          label={user.role === 3 ? 'Influencer Name ' : 'Company name'}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.companyDescription}
          onChange={(e) =>
            setFormState({
              ...formState,
              companyDescription: e.target.value,
            })
          }
          multiline
          rows={5}
          required
          label={
            user.role === 3
              ? 'Explain what you do as an influencer...'
              : 'Company Description'
          }
          fullWidth
        />
      </Grid>
      {user.role !== 3 && (
        <Grid item xs={12}>
          <TextField
            value={formState.companyKeywords}
            onChange={(e) =>
              setFormState({
                ...formState,
                companyKeywords: e.target.value,
              })
            }
            required
            label="Company keywords"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} sm={user.role === 3 ? 12 : 6}>
        <TextField
          value={formState.companyWebsite}
          onChange={(e) =>
            setFormState({
              ...formState,
              companyWebsite: e.target.value,
            })
          }
          required
          label={user.role === 3 ? 'Personal website' : 'Company website'}
          fullWidth
        />
      </Grid>
      {user.role !== 3 && (
        <Grid item xs={12} sm={6}>
          <TextField
            value={formState.privacyPolicyUrl}
            onChange={(e) =>
              setFormState({
                ...formState,
                privacyPolicyUrl: e.target.value,
              })
            }
            required
            label="Privacy policy url"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                value={formState.category}
                label="Selet Category"
                onChange={handleCategoryChange}
              >
                <MenuItem value="Halal Restaurants">Halal Restaurants</MenuItem>
                <MenuItem value="Halal Meat">Halal Meat</MenuItem>
                <MenuItem value="Groceries">Groceries</MenuItem>
                <MenuItem value="Clothing & Apparel">
                  Clothing & Apparel
                </MenuItem>
                <MenuItem value="Footwear">Footwear</MenuItem>
                <MenuItem value="Sports">Sports</MenuItem>
                <MenuItem value="Cosmetics">Cosmetics</MenuItem>
                <MenuItem value="Accountants">Accountants</MenuItem>
                <MenuItem value="Agents">Agents</MenuItem>
                <MenuItem value="Agencies">Agencies</MenuItem>
                <MenuItem value="Car Salesman">Car Salesman</MenuItem>
                <MenuItem value="Contractors">Contractors</MenuItem>
                <MenuItem value="Designing">Designing</MenuItem>
                <MenuItem value="Development">Development</MenuItem>
                <MenuItem value="Doctors">Doctors</MenuItem>
                <MenuItem value="Fashion">Fashion</MenuItem>
                <MenuItem value="Lawyers">Lawyers</MenuItem>
                <MenuItem value="Maids">Maids</MenuItem>
                <MenuItem value="Mechanics">Mechanics</MenuItem>
                <MenuItem value="Technicians">Technicians</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          {user.role === 3 ? 'Brand' : 'Company'} Identity
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FileUpload
          buttonTitle="Upload Brand Logo"
          setFileName={(name) =>
            setFormState((state) => ({
              ...state,
              photoURL: `https://firebasestorage.googleapis.com/v0/b/m1-admin-2f3a8.appspot.com/o/${name}`,
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FileUpload
          buttonTitle="Upload Banner Image"
          setFileName={(name) =>
            setFormState((state) => ({
              ...state,
              bannerURL: `https://firebasestorage.googleapis.com/v0/b/m1-admin-2f3a8.appspot.com/o/${name}`,
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Brand Colors</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography variant="h8" sx={{ fontWeight: 'bold' }}>
              Primary Color
            </Typography>
            <SketchPicker
              color={formState.brandPrimaryColor}
              onChange={(color) => handleChangeBrandColor(color, 'primary')}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h8" sx={{ fontWeight: 'bold' }}>
              Secondary Color
            </Typography>
            <SketchPicker
              color={formState.brandSecondaryColor}
              onChange={(color) => handleChangeBrandColor(color, 'secondary')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Social Links</Typography>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FacebookIcon />
          <TextField
            value={formState.facebook}
            onChange={(e) =>
              setFormState({
                ...formState,
                facebook: e.target.value,
              })
            }
            sx={{ ml: 3 }}
            required
            label="Facebook"
            fullWidth
          />
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <InstagramIcon />
          <TextField
            value={formState.instagram}
            onChange={(e) =>
              setFormState({
                ...formState,
                instagram: e.target.value,
              })
            }
            sx={{ ml: 3 }}
            required
            label="Instagram"
            fullWidth
          />
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TwitterIcon />
          <TextField
            value={formState.twitter}
            onChange={(e) =>
              setFormState({
                ...formState,
                twitter: e.target.value,
              })
            }
            sx={{ ml: 3 }}
            required
            label="Twitter"
            fullWidth
          />
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <YoutubeIcon />
          <TextField
            value={formState.youtube}
            onChange={(e) =>
              setFormState({
                ...formState,
                youtube: e.target.value,
              })
            }
            sx={{ ml: 3 }}
            required
            label="Youtube"
            fullWidth
          />
        </div>
      </Grid>
    </Grid>
  );
}
