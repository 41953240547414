import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import Title from './Title';

import { auth, db } from '../firebase/client';
import LoadingBar from './LoadingBar';
import useUser from '../hooks/useUser';
import useCampaigns from '../hooks/useCampaigns';
import useConversions from '../hooks/useConversions';
import usePixel from '../hooks/usePixel';

export default function UserMetrics({ userId }) {
  const user = useUser();
  const viewingUser = useUser(userId);
  const [walletPoints, setWalletPoints] = useState([]);
  const [loadingPoints, setLoadingPoints] = useState(true);
  const { loading: loadingPixel, items: pixelItems } = usePixel(userId);

  useEffect(() => {
    async function getWalletPoints() {
      const orderIds = pixelItems.map((item) => item['order-id']);
      const conversionRef = collection(db, 'conversions');
      const points = [];

      for (const orderId of orderIds) {
        const conversionsRefQuery = query(
          conversionRef,
          where('order_number', '==', parseInt(orderId))
        );
        const querySnapshots = await getDocs(conversionsRefQuery);
        querySnapshots.forEach((snap) => {
          const snapData = snap.data();
          points.push(snapData.makkahPoints);
        });
      }

      const totalPoints = points.reduce(
        (p, c) => parseFloat(p) + parseFloat(c),
        0
      );
      setWalletPoints(totalPoints);
    }
    if (!loadingPixel && loadingPoints) {
      getWalletPoints();
    }
  }, [loadingPixel, loadingPoints]);

  if (!viewingUser) {
    return <LoadingBar />;
  }

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Wallet Points</Title>
          <Typography component="p" variant="h4">
            {walletPoints}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Active Offers</Title>
          <Typography component="p" variant="h4">
            {viewingUser &&
              viewingUser.activeCLO &&
              viewingUser.activeCLO.length}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Purchases</Title>
          <Typography component="p" variant="h4">
            {walletPoints.length}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Linked Cards</Title>
          <Typography component="p" variant="h4">
            1
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
