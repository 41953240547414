import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/client';

export default function useUsers(role = 4) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const vendorsRef = collection(db, 'users');
    const q = query(vendorsRef, where('role', '==', role));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const docs = [];
      querySnapshot.forEach(async (document) => {
        docs.push({
          ...document.data(),
          id: document.id,
        });
      });

      setItems(docs);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return { loading, items };
}
