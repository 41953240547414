import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';

import moment from 'moment';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { auth, db } from '../../../firebase/client';
import useAccountTypesList from '../../../hooks/useAccountTypesList';
import useGeoLocation from 'react-ipgeolocation';

export default function CreateAccount() {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [phonenumber, setPhonenumber] = React.useState('92');
  const [accountType, setAccountType] = React.useState('');
  const [validateForm, setValidateForm] = React.useState(false);

  const location = useGeoLocation()
  console.log(location)

  const { accountTypesList } = useAccountTypesList()


  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidateForm(true);
    if (email !== '' && password !== '' && confirmPassword !== '') {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const { user } = userCredential;

          await setDoc(doc(db, 'users', user.uid), {
            ...user.providerData[0],
            status: 'pending',
            uid: user.uid,
            phone: phonenumber,
            role: parseInt(accountType),
            created_at: moment().format(),
          });

          await signInWithEmailAndPassword(auth, email, password);
          // set otp code
          fetch('https://m1.jannahsfamilyandfriends.com/sms')
            .then((response) => response.json())
            .then((data) => console.log(data));
          navigate('verify-account');
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          alert(errorCode.split('/')[1]);
        });
    }
  };

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5" >
        Create Your Account
      </Typography>
      <Container maxWidth="xs">
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={validateForm && email === ''}
                onChange={(event) => setEmail(event.target.value)}
                required
                fullWidth
                id="email"
                label="Business Email"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Account Type
                </InputLabel>
                <Select
                  value={accountType}
                  label="Selet Account Type"
                  onChange={(event) => setAccountType(event.target.value)}
                >
                  {Array.isArray(accountTypesList) && accountTypesList.map((item, index) => {
                    return <MenuItem value={item.value}>{item.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validateForm && password === ''}
                onChange={(event) => setPassword(event.target.value)}
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={validateForm && confirmPassword === ''}
                onChange={(event) => setConfirmPassword(event.target.value)}
                required
                fullWidth
                name="phone"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                autoComplete="confirm-password"
              />
            </Grid>

            <Grid item xs={12} sx={{position:'relative'}}>
              {/* <Box sx={{ display: 'flex' }}>
                <TextField
                  error={validateForm && phonenumber === ''}
                  onChange={(event) => setPhonenumber(event.target.value)}
                  required
                  name="phone"
                  label="Phone Number"
                  type="phone"
                  id="phone"
                  autoComplete="phone"
                  sx={{ flex: 1 }}
                />
              </Box> */}
              <PhoneInput
                country={'us'}
                value={phonenumber}
                enableSearch = {true}
                onChange={phone =>{
                  setPhonenumber(phone)
                }}
                containerStyle={{
                  position:'relative',
                  height:'100%'
                }}
                inputStyle={{
                  width:'100%',
                  height:'50px'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="By checking this box you agree to our terms of agreement"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
