import * as React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';

import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useMatch } from 'react-router-dom';

import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import VendorMetrics from '../../components/VendorMetrics';
import VendorRecentSales from '../../components/VendorRecentSales';
import VendorRevenueChart from '../../components/VendorRevenueChart';

import useCampaigns from '../../hooks/useCampaigns';
import LoadingBar from '../../components/LoadingBar';
import { db } from '../../firebase/client';
import useUser from '../../hooks/useUser';
import Title from '../../components/Title';
import AccountInfoStep from '../../components/AccountInfoStep';
import AddressInfoStep from '../../components/AddressInfoStep';
import CompanyBrandInfoStep from '../../components/CompanyBrandInfoStep';
import VendorCampaigns from '../../components/VendorCampaigns';
import TabPanel from '../../components/TabPanel';

export default function Vendor() {
  const {
    params: { id },
  } = useMatch('/admin/vendors/:id');
  const { loading, items: campaigns } = useCampaigns(id);
  const vendor = useUser(id);
  const [conversions, setConversions] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(0);

  const [formState, setFormState] = React.useState({
    firstName: null,
    lastName: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    postal: null,
    province: null,
    country: null,
    brandPrimaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    brandSecondaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    companyName: null,
    companyDescription: null,
    companyKeywords: null,
    privacyPolicyUrl: null,
    category: null,
    subcategory: null,
  });

  const changeTab = (event, newTabStep) => {
    setCurrentTab(newTabStep);
  };

  const updateAccStatus = async (accStatus) => {
    try {
      const vendorRef = doc(db, 'users', id);
      await updateDoc(vendorRef, {
        status: accStatus,
      });

      toast('Account status updated');
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    async function getConversions() {
      const conversionsRef = collection(db, 'conversions');
      const conversionsQuery = query(
        conversionsRef,
        where('vendorId', '==', id)
      );

      const unsubscribe = onSnapshot(
        conversionsQuery,
        async (querySnapshot) => {
          const docs = [];
          querySnapshot.forEach(async (document) => {
            docs.push({ ...document.data() });
          });
          setConversions(docs);
        }
      );
      return unsubscribe;
    }
    getConversions();
  }, []);

  React.useEffect(() => {
    if (vendor) {
      setFormState({
        ...vendor,
      });
    }
  }, [vendor]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {vendor && <Title>{vendor.companyName}</Title>}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={changeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Conversions & Sales" />
          <Tab label="Campaigns" />
          <Tab label="Account Information" />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        {!loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VendorMetrics items={campaigns} conversions={conversions} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <VendorRevenueChart conversions={conversions} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <VendorRecentSales conversions={conversions} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <LoadingBar />
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Title>Campaigns</Title>
          <VendorCampaigns items={campaigns} url="/admin/campaigns/" />
        </Paper>
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        {vendor ? (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  pb: 8,
                }}
              >
                <Stack justifyContent="center" alignItems="center" mt={5}>
                  <img
                    src={vendor.photoURL}
                    alt="profile"
                    style={{
                      width: '120px',
                      height: '120px',
                      borderRadius: '160px',
                      overflow: 'hidden',
                    }}
                  />
                  <Typography variant="body1" fontWeight="bold" mt={2}>
                    {vendor.companyName}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="a"
                    href={vendor.companyWebsite}
                  >
                    {vendor.companyWebsite}
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {vendor.status === 'completed' && 'Account Active'}
                  </Typography>
                  {vendor.status === 'pending' ||
                    (vendor.status === 'blocked' && (
                      <Button
                        onClick={() => updateAccStatus('active')}
                        variant="outlined"
                        color="success"
                        style={{ marginTop: 30, marginBottom: 10 }}
                      >
                        Approve Vendor
                      </Button>
                    ))}
                  {(vendor.status === 'active' ||
                    vendor.status === 'completed') && (
                    <Button
                      onClick={() => updateAccStatus('blocked')}
                      style={{ marginTop: 30 }}
                      color="error"
                    >
                      Block Vendor
                    </Button>
                  )}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 3,
                }}
              >
                <Title>Profile Information</Title>
                <AccountInfoStep
                  formState={formState}
                  setFormState={setFormState}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 3,
                }}
              >
                <Title>Billing Address</Title>
                <AddressInfoStep
                  formState={formState}
                  setFormState={setFormState}
                />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Title>Branding</Title>
                <CompanyBrandInfoStep
                  formState={formState}
                  setFormState={setFormState}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <LoadingBar />
        )}
      </TabPanel>
    </Container>
  );
}
