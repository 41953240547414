// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAEK4byUlBNSQzI020Qh1u2h5Z5g-sRS_Q',
  authDomain: 'm1-admin-2f3a8.firebaseapp.com',
  projectId: 'm1-admin-2f3a8',
  storageBucket: 'm1-admin-2f3a8.appspot.com',
  messagingSenderId: '395161669004',
  appId: '1:395161669004:web:5c9c1107dc7686de009aa9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

export { app, db, auth, storage };
