import React, { useEffect, useState, useMemo } from 'react'
import useUmmrahLotteryUsers from '../../hooks/useUmmrahLotteryUsers';
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Modal from '@mui/material/Modal';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack'
import ReactCountryFlag from "react-country-flag"
import { Typography } from '@mui/material';


const UmmrahLottery = (props) => {
    const DEFAULT_PER_PAGE_COUNT = 7
    const [perPageCount, setPerPageCount] = useState(DEFAULT_PER_PAGE_COUNT)
    const [modalVisibility, setModalVisibility] = useState(false)
    const [page, setPage] = useState(0)
    const [pagesCount, setPagesCount] = useState(0)
    const [rowsPageOptions, setRowsPageOptions] = useState([])
    const { ummrahLotteryUsersList: rows } = useUmmrahLotteryUsers()
    const [selectedRowData, setSelectedRowData] = useState()


    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const [data, setData] = useState([])

    const handleOnPageChange = (event, value) => {
        setPage(value)
        setData(rows.slice((value) * perPageCount, (value + 1) * perPageCount))
    }
    const handleChangeRowsPerPage = (event) => {
        const perPageValue = parseInt(event.target.value, 10)
        setPerPageCount(perPageValue);
        let totalPages = Math.ceil((rows.length) / (perPageValue))
        setPagesCount(totalPages)
        setPage(0)
        setData(rows.slice(0, perPageValue))
    };

    useMemo(() => {
        setPagesCount(Math.ceil((rows.length) / DEFAULT_PER_PAGE_COUNT))
        let options = new Array(rows.length).
            fill(null).
            map((__, index) => {
                let temp = index * 10
                if ((temp > 0) && (temp <= rows.length)) {
                    return temp
                }
            }).filter(item => item)
        setRowsPageOptions(options)
    }, [])

    useEffect(() => {
        if (data?.length < 1) {
            setData(rows.slice(0, DEFAULT_PER_PAGE_COUNT + 1))
        }

    }, [rows])

    const handleRowClick = (data) => {
        if (selectedRowData?.id == data?.id) {
            setModalVisibility(true)
        } else {
            var invitedUsers = []
            if (data?.referelCodesAcceptors) {
                let tempUsers = data.referelCodesAcceptors.map(item => {
                    return rows.filter(user => user.id == item.acceptor).map(u => {
                        return {
                            'name': u.firstname + " " + u.lastname,
                            'country': u.country,
                            'date': item.date
                        }
                    })[0]
                })
                invitedUsers = tempUsers
            }
            setSelectedRowData({ ...data, referelCodesAcceptors: invitedUsers })
            setModalVisibility(true)
        }
    }

    console.log('data : ', selectedRowData)


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: 'solid 0px white',
        boxShadow: 24,
        width: '500px',
        p: 2
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: "100vh",
                    overflow: "auto",
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Toolbar />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Country</TableCell>
                                <TableCell align="left">Phone</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Device</TableCell>
                                <TableCell align="left">Gender</TableCell>
                                <TableCell align="left">Refrels</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow
                                    key={row.name + index}
                                    style={{ 'cursor': 'pointer' }}
                                    sx={{ ' &:last-child td, &:last-child th': { border: 0 } }}
                                    onClick={(e) => handleRowClick(row)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row?.firstname + " " + row?.lastname}
                                    </TableCell>
                                    <TableCell align="left">
                                        <span style={{
                                            paddingRight: '5px'
                                        }}>
                                            <ReactCountryFlag
                                                countryCode={row?.country?.code}
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '1.5em'
                                                }}
                                            />
                                        </span>
                                        <spa>({row?.country.name})</spa>
                                    </TableCell>
                                    <TableCell align="left">{row?.phone}</TableCell>
                                    <TableCell align="left">{row?.ummrahLotteryApplicationDate}</TableCell>
                                    <TableCell align="left">{row?.deviceInformations?.osName}<span style={{
                                        paddingLeft: '5px'
                                    }}>({row?.deviceInformations?.modelName})</span></TableCell>
                                    <TableCell align="left">{row?.gender == 1 ? 'femal' : 'male'}</TableCell>
                                    <TableCell align="left">{row?.referelCodesAcceptors ? row?.referelCodesAcceptors?.length : 0}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box sx={{
                    paddingBottom: '5px',
                    paddingTop: '5px',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                }}
                    component='div'
                >
                    <Stack spacing={5}>
                        <TablePagination
                            component="div"
                            count={rows.length}
                            page={page}
                            onPageChange={handleOnPageChange}
                            rowsPerPage={perPageCount}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={rowsPageOptions}
                            labelRowsPerPage='Per page'
                        />
                    </Stack>
                </Box>
            </Box>
            <Modal
                open={modalVisibility}
                onClose={(e) => {
                    setModalVisibility(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ textAlign: 'center' }}>
                        {selectedRowData?.firstname + " " + selectedRowData?.lastname}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ mt: 1, fontSize: '16px' }}>
                        <Box style={{ display: 'inline', paddingRight: '3px' }}>
                            {`Country: ${selectedRowData?.country.name}`}
                        </Box>
                        (
                        {selectedRowData && (

                            <ReactCountryFlag
                                countryCode={selectedRowData?.country?.code}
                                svg
                                style={{
                                    width: '1.2em',
                                    height: '1.2em',
                                    paddingLeft: '2px',
                                    paddingRight: '2px'
                                }}
                            />
                        )})
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Email : ${selectedRowData?.email}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Gender : ${selectedRowData?.gender == 0 ? 'Male' : 'Female'}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant="h5" component="h2"
                        sx={{ fontSize: '16px', textDecorationLine: 'underline', mt: 1 }}>DEVICE INFORMATIONS</Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Device Brand : ${selectedRowData?.deviceInformations?.brand}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Device Design Name : ${selectedRowData?.deviceInformations?.designName}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Device OS : ${selectedRowData?.deviceInformations?.osName}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Device OS Version : ${selectedRowData?.deviceInformations?.osVersion}`}
                    </Typography>
                    <Typography id="modal-modal-description" variant='h6' sx={{ fontSize: '16px' }}>
                        {`Device Platform ApiLevel : ${selectedRowData?.deviceInformations?.platformApiLevel}`}
                    </Typography>
                    {selectedRowData?.referelCodesAcceptors?.length > 0 && (
                        <Typography id="modal-modal-description" variant="h5" component="h2" sx={{ fontSize: '16px', textDecorationLine: 'underline', mt: 1 }}>
                            REFRALLS ACCEPTED BY
                        </Typography>)
                    }
                    {
                        Array.isArray(selectedRowData?.referelCodesAcceptors) && selectedRowData?.referelCodesAcceptors.map((item, index) => {
                            const acceptanceDate = new Date(item.date)
                            const formatedDate = acceptanceDate.toLocaleDateString()
                            return (
                                <Box key={index + ""}>
                                    <Typography sx={{ mt: 1 }}>
                                        <Box sx={{ display: 'inline' }}>{item?.name}</Box>
                                        <Box sx={{ display: 'inline', paddingRight: '10px', paddingLeft: '10px' }}>
                                            <ReactCountryFlag
                                                countryCode={item?.country?.code}
                                                svg
                                                style={{
                                                    width: '1.2em',
                                                    height: '1.2em',
                                                    paddingLeft: '2px',
                                                    paddingRight: '2px'
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ display: 'inline', }}>{formatedDate}</Box>
                                    </Typography>
                                </Box>
                            )
                        })
                    }

                </Box>
            </Modal>
        </>
    )
}


export default UmmrahLottery