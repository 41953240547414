import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import Title from './Title';
// Generate Order Data
function createData(id, date, name, shipTo, paymentMethod, amount) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  // createData(
  //   0,
  //   '16 Mar, 2019',
  //   'Spring Campaign',
  //   'Card-Linked Offer',
  //   'Pending',
  //   10
  // ),
];

export default function InStoreTransactions() {
  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Title>In-Store Shopping Transactions</Title>
        <Button variant="contained">
          <DownloadIcon />
          Download PDF
        </Button>
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Purchase Amount</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Merchant #</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Payment Method</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Point Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{row.amount} Points</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
