import React from 'react';

import Paper from '@mui/material/Paper';
import AdminUsersList from '../../components/AdminUsersList';

export default function UsersList() {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <AdminUsersList />
    </Paper>
  );
}
