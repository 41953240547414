import { collection, doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase/client';

export default function useUser(id) {
  const { currentUser } = auth;
  const [user, setUser] = useState();

  useEffect(
    () =>
      auth.onAuthStateChanged(async (data) => {
        if (data) {
          const uid = id || currentUser.uid;
          const userRef = doc(collection(db, 'users'), uid);
          const getUser = await getDoc(userRef);
          if (getUser.exists()) {
            setUser({
              ...data,
              ...getUser.data(),
            });
          }
        } else {
          setUser(null);
        }
      }),
    []
  );

  return user;
}
