import * as React from 'react';
import Typography from '@mui/material/Typography';

function Title({ children, color }) {
  return (
    <Typography
      component="h2"
      variant="h6"
      color={color || 'primary'}
      gutterBottom
    >
      {children}
    </Typography>
  );
}

export default Title;
