import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import PromotionPlatformSelectStep from './PromotionPlatformSelectStep';
import PromotionPostStyleStep from './PromotionPostStyleStep';
import PromotionPostBotStep from './PromotionPostBotStep';

const steps = ['Select Platform', 'Select Post Type', 'Promote Offer'];

const captions = [
  'Step 1 - Which platform do you want to promote this offer?',
  'Select Post Type',
  'Edit Post Details',
  'Promote Offer',
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function PromotionDialogue({ open, handleClose, campaign }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formState, setFormState] = useState({
    type: 1,
    caption: `Excited to share this ${campaign.campaignName} offer availabe on MakkahOne iOS or Android app.  😜❄️ ${campaign.tags}`,
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => {
        handleClose();
        setActiveStep(0);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box sx={{ ...style, width: '90%' }}>
        Promote {open && campaign.campaignName} <br />
        <br />
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              {captions[activeStep]}
            </Typography>
            {activeStep === 0 && (
              <PromotionPlatformSelectStep
                formState={formState}
                setFormState={setFormState}
                handleClickNext={handleNext}
              />
            )}
            {activeStep === 1 && (
              <PromotionPostStyleStep
                formState={formState}
                campaign={campaign}
                next={handleNext}
                setFormState={setFormState}
              />
            )}
            {activeStep === 2 && (
              <PromotionPostBotStep
                formState={formState}
                setFormState={setFormState}
                campaign={campaign}
                next={handleNext}
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}
