import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/client';

export default function usePromotions(user) {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (user && loading) {
      const id = typeof user === 'object' ? user.uid : user;
      const promotionsRef = collection(db, 'promotions');
      const q =
        user.role === 1
          ? query(promotionsRef)
          : query(promotionsRef, where('uid', '==', id));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const docs = [];
        querySnapshot.forEach(async (document) => {
          docs.push({
            ...document.data(),
            id: document.id,
          });
        });

        setItems(docs);
        setLoading(false);
      });

      return unsubscribe;
    }
  }, [user, loading]);

  return { loading, items };
}
