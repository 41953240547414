import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import {
  Button,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Title from '../../components/Title';
import useUser from '../../hooks/useUser';
import AccountInfoStep from '../../components/AccountInfoStep';
import AddressInfoStep from '../../components/AddressInfoStep';
import CompanyBrandInfoStep from '../../components/CompanyBrandInfoStep';
import LoadingBar from '../../components/LoadingBar';

export default function Account() {
  const user = useUser();
  const [formState, setFormState] = React.useState({
    firstName: null,
    lastName: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    postal: null,
    province: null,
    country: null,
    brandPrimaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    brandSecondaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    companyName: null,
    companyDescription: null,
    companyKeywords: null,
    privacyPolicyUrl: null,
    category: null,
    subcategory: null,
  });

  React.useEffect(() => {
    if (user) {
      setFormState({
        ...user,
      });
    }
  }, [user]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {user ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    pb: 8,
                  }}
                >
                  <Stack justifyContent="center" alignItems="center" mt={5}>
                    <img
                      src={user.photoURL}
                      alt="profile"
                      style={{
                        width: '120px',
                        height: '120px',
                        borderRadius: '160px',
                        overflow: 'hidden',
                      }}
                    />
                    <Typography variant="body1" fontWeight="bold" mt={2}>
                      {user.companyName}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="a"
                      href={user.companyWebsite}
                    >
                      {user.companyWebsite}
                    </Typography>
                    <Typography variant="body2" mt={1}>
                      Administrator
                    </Typography>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper
                  sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 3,
                  }}
                >
                  <Title>Profile Information</Title>
                  <AccountInfoStep
                    formState={formState}
                    setFormState={setFormState}
                  />
                </Paper>
              </Grid>
            </Grid>{' '}
          </>
        ) : (
          <LoadingBar />
        )}
      </Container>
    </Box>
  );
}
