import React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Title from './Title';

export default function CampaignMetrics({
  totalImpressions,
  totalPoints,
  totalClicks,
  totalProfit,
  totalConversions,
}) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Revenue</Title>
          <Typography component="p" variant="h4">
            $0
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Profit</Title>
          <Typography component="p" variant="h4">
            ${totalProfit}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Points</Title>
          <Typography component="p" variant="h4">
            {totalPoints}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Conversions</Title>
          <Typography component="p" variant="h4">
            {totalConversions}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Clicks</Title>
          <Typography component="p" variant="h4">
            {totalClicks}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6} sm={4}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 130,
          }}
        >
          <Title>Total Impressions</Title>
          <Typography component="p" variant="h4">
            {totalImpressions}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
}
