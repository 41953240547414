import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ref, uploadBytes } from 'firebase/storage';
import { auth, storage } from '../firebase/client';

export default function FileUpload({ setFileName, buttonTitle }) {
  const user = auth.currentUser;
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const changeHandler = (event) => {
    setFileName(`${user.uid}%2F${event.target.files[0].name}?alt=media`);
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    setImageUrl(URL.createObjectURL(event.target.files[0]));

    const storageRef = ref(
      storage,
      `${user.uid}/${event.target.files[0].name}`
    );

    uploadBytes(storageRef, event.target.files[0])
      .then((snapshot) => {
        console.log(snapshot);
      })
      .catch((error) => console.log(error));
  };

  //   const handleSubmission = () => {
  //     const formData = new FormData();

  //     formData.append('File', selectedFile);

  //     fetch('https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>', {
  //       method: 'POST',
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((result) => {
  //         console.log('Success:', result);
  //       })
  //       .catch((error) => {
  //         console.error('Error:', error);
  //       });
  //   };

  return (
    <div
      style={{
        border: '2px dashed lightgray',
        width: '100%',
        padding: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {isFilePicked && selectedFile ? (
        <div>
          {console.log(selectedFile)}
          {imageUrl && (
            <img
              src={imageUrl}
              id="blah"
              alt="preview"
              style={{ width: '300px' }}
            />
          )}
          <p>Filename: {selectedFile.name}</p>
          <p>Filetype: {selectedFile.type}</p>
          <p>Size in bytes: {selectedFile.size}</p>
          <Button
            variant="contained"
            component="label"
            onClick={() => {
              setSelectedFile(null);
              setIsFilePicked(false);
              setImageUrl(null);
            }}
          >
            Remove File
          </Button>
        </div>
      ) : (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="contained" component="label" onClick={handleClick}>
            {buttonTitle || 'Upload File'}
          </Button>
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            ref={hiddenFileInput}
            onChange={changeHandler}
          />
        </Stack>
      )}
    </div>
  );
}
