import React, { useState } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { collection, doc, getDoc } from 'firebase/firestore';
import Copyright from '../../../components/Copyright';
import { auth, db } from '../../../firebase/client';

import logo from '../../../logo.png';

// email:'saad@funadiq.com'
// passowrd:'funadiq2017@@@'
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [validateForm, setValidateForm] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidateForm(true);
    if (email !== '' && password !== '') {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (data) => {
          const userRef = doc(collection(db, 'users'), auth.currentUser.uid);
          const getUser = await getDoc(userRef);
          if (getUser.exists()) {
            if (getUser.data().status === 'pending') {
              navigate('/register/verify-account');
            } else if (getUser.data().status === 'complete-profile') {
              navigate('/register/complete-profile');
            } else if (getUser.data().role === 1) {
              navigate('/admin/home');
            } else if (getUser.data().role === 2) {
              navigate('/vendor/home');
            } else if (getUser.data().role === 3) {
              navigate('/influencer/home');
            }
          }
        })
        .catch((error) => {
          alert(error.code.split('/')[1]);
        });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={logo}
          alt="Makkah One"
          style={{ height: '70px', marginBottom: 20 }}
        />
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            error={validateForm && email === ''}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            defaultValue=''
          />
          <TextField
            error={validateForm && password === ''}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            defaultValue=''
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
