export default [
  {
    name: "Home",
    path: "home",
    icon: "",
  },
  {
    name: "Ummrah Lottery",
    path: "lottery",
    icon: "",
  },
  // {
  //   name: 'Analytics',
  //   path: 'analytics',
  //   icon: '',
  // },
  {
    name: "Campaigns",
    path: "campaigns",
    icon: "",
  },
  {
    name: "Vendors",
    path: "vendors",
    icon: "",
  },
  {
    name: "Users",
    path: "users",
    icon: "",
  },
  {
    name: "Account",
    path: "account",
    icon: "",
  },
];
