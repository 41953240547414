import * as React from 'react';
import { Outlet } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Copyright from '../components/Copyright';

import logo from '../logo.png';

export default function RegistrationLayout() {
  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',

        }}
      >
        <img
          src={logo}
          alt="Makkah One"
          style={{ height: '70px', marginBottom: 20 }}
        />
        <Outlet />
        <Copyright sx={{ mt: 5 }} />
      </Box>
    </Container>
  );
}
