import {
    collection,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/client';


const useAccountTypesList = () => {

    const [accountTypesList, setAccountTypesList] = useState([])

    const getData = async () => {
        try {
            let list = []
            const accountTypesRef = collection(db, 'account_types_dropdown')
            const querySnapshot = await getDocs(accountTypesRef)
            querySnapshot.forEach((doc) => {
                list.push(doc.data().item)
              });
              setAccountTypesList(list)
        } catch (error) {
            console.log('d', error)
        }

    }

    useEffect(() => {
        getData()
    }, [])

    return {accountTypesList}
}


export default useAccountTypesList