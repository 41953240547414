import React from 'react';

import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';

import Title from './Title';
import AudienceLocations from './AudienceLocations';
import LanguageList from './LanguageList';

export default function CampaignAudienceStep({ formState, setFormState }) {
  const setCities = (cities) =>
    setFormState({
      ...formState,
      locations: cities,
    });

  console.log(formState);
  return (
    <>
      <Title>Select Audience</Title>
      <Grid container spacing={1} px={3} mt={3} justifyContent="center">
        <Grid item xs={12} mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            1. Select Locations
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AudienceLocations setCities={setCities} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            2. Select Age
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Box width={80}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formState.minAge}
                  fullWidth
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      minAge: e.target.value,
                    })
                  }
                >
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={26}>26</MenuItem>
                  <MenuItem value={27}>27</MenuItem>
                  <MenuItem value={28}>28</MenuItem>
                  <MenuItem value={29}>29</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={31}>31</MenuItem>
                  <MenuItem value={32}>32</MenuItem>
                  <MenuItem value={33}>33</MenuItem>
                  <MenuItem value={34}>34</MenuItem>
                  <MenuItem value={35}>35</MenuItem>
                  <MenuItem value={36}>36</MenuItem>
                  <MenuItem value={37}>37</MenuItem>
                  <MenuItem value={38}>38</MenuItem>
                  <MenuItem value={39}>39</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={41}>41</MenuItem>
                  <MenuItem value={42}>42</MenuItem>
                  <MenuItem value={43}>43</MenuItem>
                  <MenuItem value={44}>44</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={46}>46</MenuItem>
                  <MenuItem value={47}>47</MenuItem>
                  <MenuItem value={48}>48</MenuItem>
                  <MenuItem value={49}>49</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={51}>51</MenuItem>
                  <MenuItem value={52}>52</MenuItem>
                  <MenuItem value={53}>53</MenuItem>
                  <MenuItem value={54}>54</MenuItem>
                  <MenuItem value={55}>55</MenuItem>
                  <MenuItem value={56}>56</MenuItem>
                  <MenuItem value={57}>57</MenuItem>
                  <MenuItem value={58}>58</MenuItem>
                  <MenuItem value={59}>59</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={61}>61</MenuItem>
                  <MenuItem value={62}>62</MenuItem>
                  <MenuItem value={63}>63</MenuItem>
                  <MenuItem value={64}>64</MenuItem>
                  <MenuItem value={65}>65</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box>-</Box>
            <Box width={80}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formState.maxAge}
                  onChange={(event) =>
                    setFormState({
                      ...formState,
                      maxAge: event.target.value,
                    })
                  }
                >
                  <MenuItem value={18}>18</MenuItem>
                  <MenuItem value={19}>19</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={21}>21</MenuItem>
                  <MenuItem value={22}>22</MenuItem>
                  <MenuItem value={23}>23</MenuItem>
                  <MenuItem value={24}>24</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={26}>26</MenuItem>
                  <MenuItem value={27}>27</MenuItem>
                  <MenuItem value={28}>28</MenuItem>
                  <MenuItem value={29}>29</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={31}>31</MenuItem>
                  <MenuItem value={32}>32</MenuItem>
                  <MenuItem value={33}>33</MenuItem>
                  <MenuItem value={34}>34</MenuItem>
                  <MenuItem value={35}>35</MenuItem>
                  <MenuItem value={36}>36</MenuItem>
                  <MenuItem value={37}>37</MenuItem>
                  <MenuItem value={38}>38</MenuItem>
                  <MenuItem value={39}>39</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={41}>41</MenuItem>
                  <MenuItem value={42}>42</MenuItem>
                  <MenuItem value={43}>43</MenuItem>
                  <MenuItem value={44}>44</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                  <MenuItem value={46}>46</MenuItem>
                  <MenuItem value={47}>47</MenuItem>
                  <MenuItem value={48}>48</MenuItem>
                  <MenuItem value={49}>49</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={51}>51</MenuItem>
                  <MenuItem value={52}>52</MenuItem>
                  <MenuItem value={53}>53</MenuItem>
                  <MenuItem value={54}>54</MenuItem>
                  <MenuItem value={55}>55</MenuItem>
                  <MenuItem value={56}>56</MenuItem>
                  <MenuItem value={57}>57</MenuItem>
                  <MenuItem value={58}>58</MenuItem>
                  <MenuItem value={59}>59</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                  <MenuItem value={61}>61</MenuItem>
                  <MenuItem value={62}>62</MenuItem>
                  <MenuItem value={63}>63</MenuItem>
                  <MenuItem value={64}>64</MenuItem>
                  <MenuItem value={65}>65</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            3. Select Gender
          </Typography>
        </Grid>
        <Grid item xs={12} mb={3}>
          <ButtonGroup variant="outlined" aria-label="outlined button group">
            <Button
              onClick={() =>
                setFormState({
                  ...formState,
                  gender: 'all',
                })
              }
              variant={formState.gender === 'all' ? 'contained' : 'outlined'}
            >
              All
            </Button>
            <Button
              onClick={() =>
                setFormState({
                  ...formState,
                  gender: 'male',
                })
              }
              variant={formState.gender === 'male' ? 'contained' : 'outlined'}
            >
              Male
            </Button>
            <Button
              onClick={() =>
                setFormState({
                  ...formState,
                  gender: 'female',
                })
              }
              variant={formState.gender === 'female' ? 'contained' : 'outlined'}
            >
              Female
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
            4. Select Languages
          </Typography>
        </Grid>
        <Grid item xs={12} mb={5}>
          <LanguageList formState={formState} setFormState={setFormState} />
        </Grid>
      </Grid>
    </>
  );
}
