import { Button } from "@mui/material";
import React, { useEffect } from "react";
import CameraIcon from "@mui/icons-material/CameraAltRounded";
import CloseIcon from "@mui/icons-material/Close";
import useUser from "../hooks/useUser";

const styles = {
  instagram_card: {
    background:
      "url(https://buffer.com/resources/content/images/resources/wp-content/uploads/2019/12/luke-chesser-B_oL3jEt5L4-unsplash.jpg)",
    border: "1px solid #f2f2f2",
    borderRadius: "3px",
    width: "100%",
    maxWidth: "600px",
    margin: "auto",
  },
  instagram_card_header: { padding: "20px", height: "40px" },
  instagram_card_user_image: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    verticalAlign: "middle",
  },
  instagram_card_time: {
    float: "right",
    width: "80px",
    paddingTop: "10px",
    textAlign: "right",
    color: "#999",
  },
  instagram_card_user_name: {
    marginLeft: "20px",
    fontWeight: "bold",
    color: "#ffff",
  },
  instagram_card_content: { padding: "20px" },
  likes: { fontWeight: "bold" },
  instagram_card_content_user: { fontWeight: "bold", color: "#262626" },
  hashtag: { color: "#003569" },
  comments: { color: "#999" },
  user_comment: { color: "#003569" },
  instagram_card_footer: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
  },
  hr: {
    border: "none",
    borderBottom: "1px solid #ccc",
    marginTop: "30px",
    marginBottom: "0px",
  },
  footer_action_icons: { fontSize: "16px", color: "#ccc" },
  comments_input: {
    border: "none",
    marginLeft: "10px",
    width: "100%",
    background: "transparent",
    color: "white",
    paddingLeft: "40px",
  },
  intagram_card_image: {
    width: "100%",
    marginTop: "35px",
    textAlign: "center",
    height: "825px",
  },
};

export default function InstagramStory({ campaign, onSelect, type }) {
  const user = useUser();
  if (!user) {
    return "";
  }
  return (
    <div
      style={{
        ...styles.instagram_card,
        border: type === 2 ? `3px solid blue` : "1px solid #f2f2f2",
      }}
    >
      <div style={styles.instagram_card_header}>
        <img
          alt="img"
          src={user.photoURL}
          style={styles.instagram_card_user_image}
        />
        <a
          style={styles.instagram_card_user_name}
          href="https://www.instagram.com/"
        >
          {user.companyName}
        </a>
        <div style={styles.instagram_card_time}>
          <CloseIcon style={{ color: "white", fontSize: "25px" }} />
        </div>
      </div>

      <div style={styles.intagram_card_image}>
        <img
          alt="img"
          src={campaign.campaignImage}
          style={{
            width: "400px",
            position: "relative",
            top: 150,
          }}
        />
        <p
          style={{
            position: "relative",
            top: 200,
            paddingRight: "120px",
            paddingLeft: "120px",
            textAlign: "center",
            fontWeight: "bolder",
            color: "white",
            fontSize: "32px",
          }}
        >
          Excited to share this Ramadan Special offer availabe on MakkahOne iOS
          or Android app. 😜❄️
        </p>
      </div>

      <div style={styles.instagram_card_footer}>
        <a style={styles.footer_action_icon} href="#">
          <i className="fa fa-heart-o" />
        </a>
        <div
          style={{
            border: "1px solid lightgray",
            width: "100%",
            borderRadius: "40px",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <CameraIcon
            style={{
              position: "absolute",
              color: "white",
              marginLeft: "15px",
              marginTop: "1px",
            }}
          />
          <input
            style={styles.comments_input}
            type="text"
            value="Leave a comment..."
          />
        </div>
        <a style={styles.footer_action_icons} href="#">
          <i className="fa fa-ellipsis-h" />
        </a>
      </div>
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <Button
          onClick={() => onSelect(2)}
          sx={{ color: "white", fontWeight: "bold" }}
        >
          Promote as Instagram Story
        </Button>
      </div>
    </div>
  );
}
