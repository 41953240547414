import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/client';

export default function useCampaign(id) {
  const [item, setItem] = useState();
  useEffect(
    () =>
      onSnapshot(doc(db, 'campaigns', id), (querySnapshot) => {
        setItem(querySnapshot.data());
      }),
    []
  );

  return item;
}
