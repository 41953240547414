import * as React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';

const rows = [
  // createData(
  //   0,
  //   '16 Mar, 2019',
  //   'Spring Campaign',
  //   'Card-Linked Offer',
  //   'Mastercard',
  //   'Nike Air Force',
  //   312.44
  // ),
];

export default function CampaignSales({ items }) {
  return (
    <>
      <Title>Latest Conversions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Id</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Sale Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(items) && items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {moment(row.created_at).format('LL @ hh:mm A')}
              </TableCell>
              <TableCell>{Array.isArray(row.line_items) && row.line_items.map((item) => item.name)}</TableCell>
              <TableCell>
                {Array.isArray(row.line_items) && row.line_items.map((item) => item.product_id)}
              </TableCell>
              <TableCell align="right">
                {`$${row.total_price}`} {row.currency}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
