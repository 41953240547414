import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YoutubeIcon from '@mui/icons-material/YouTube';

export default function ReviewStep({ formState }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Company Name:{' '}
        </Typography>
        <Typography variant="body1">{formState.companyName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Company Description:{' '}
        </Typography>
        <Typography variant="body1">{formState.companyDescription}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Brand Keywords:{' '}
        </Typography>
        <Typography variant="body1">{formState.companyKeywords}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Company Website Url:{' '}
        </Typography>
        <Typography variant="body1" component="a" href="#">
          {formState.companyWebsite}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Privacy Policy Url:{' '}
        </Typography>
        <Typography variant="body1" component="a" href="#">
          {formState.privacyPolicyUrl}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Category:{' '}
            </Typography>
            <Typography variant="body1">{formState.category}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Sub Category:{' '}
            </Typography>
            <Typography variant="body1">{formState.subcategory}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Company Identity</Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          src={formState.photoURL}
          alt="company logo"
          style={{ width: '200px' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Brand Colors</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} sm={6}>
            <Typography variant="h8" sx={{ fontWeight: 'bold' }}>
              Primary Color
            </Typography>{' '}
            <br />
            <br />
            <div
              style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: `0 0 0 1px rgba(0,0,0,.1)`,
                display: 'inline-block',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  width: '36px',
                  height: '14px',
                  borderRadius: '2px',
                  background: ` rgba(${formState.brandPrimaryColor.r},${formState.brandPrimaryColor.g},${formState.brandPrimaryColor.b},1)`,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h8" sx={{ fontWeight: 'bold' }}>
              Secondary Color
            </Typography>{' '}
            <br />
            <br />
            <div
              style={{
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  width: '36px',
                  height: '14px',
                  borderRadius: '2px',
                  background: `rgba(${formState.brandSecondaryColor.r},${formState.brandSecondaryColor.g},${formState.brandSecondaryColor.b},1)`,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider light />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Social Links</Typography>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FacebookIcon />
          <Typography variant="body1" component="a" href="#" sx={{ ml: 3 }}>
            {formState.facebook}
          </Typography>
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <InstagramIcon />
          <Typography variant="body1" component="a" href="#" sx={{ ml: 3 }}>
            {formState.instagram}
          </Typography>
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <TwitterIcon />
          <Typography variant="body1" component="a" href="#" sx={{ ml: 3 }}>
            {formState.twitter}
          </Typography>
        </div>
      </Grid>
      <Grid item justifyContent="space-between" xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <YoutubeIcon />
          <Typography variant="body1" component="a" href="#" sx={{ ml: 3 }}>
            {formState.youtube}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}
