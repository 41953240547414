export default [
  {
    name: 'Home',
    path: 'home',
    icon: '',
  },
  {
    name: 'Promotions',
    path: 'promotions',
    icon: '',
  },
  {
    name: 'Invoices',
    path: 'invoices',
    icon: '',
  },
  {
    name: 'Account',
    path: 'account',
    icon: '',
  },
];
