import { styled } from '@mui/material/styles';

import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

/** Icons */
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';

import { auth } from '../firebase/client';
import useUser from '../hooks/useUser';

import adminMenu from '../config/admin.menu';
import vendorMenu from '../config/vendor.menu';
import influencerMenu from '../config/influencer.menu';

const drawerWidth = 240;

const DrawerStyled = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const menus = {
  1: adminMenu,
  2: vendorMenu,
  3: influencerMenu,
};

export default function Drawer({ open, variant, userRole, toggleDrawer }) {
  const navigate = useNavigate();
  const user = useUser();
  const menuItems = menus[userRole];

  const handleMenuClick = (menuItem) => {
    navigate(menuItem.path);
  };

  const handleSignout = async () => {
    await auth.signOut();
    navigate('/login');
  };

  if (!user) {
    return <div />;
  }

  return (
    <DrawerStyled variant={variant} open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [2],
        }}
      >
        {user.role === 1 ? (
          'Makkah One'
        ) : (
          <>
            {' '}
            <img
              src={user.photoURL}
              style={{
                width: '30px',
                height: '30px',
                overflow: 'hidden',
                borderRadius: '100px',
              }}
              alt="logo"
            />
            <a
              href={`https://${user.companyWebsite}`}
              target="_blank"
              rel="noreferrer"
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                color: 'black',
                textDecoration: 'none',
                fontWeight: 'bold',
              }}
            >
              {user.companyName}
            </a>
          </>
        )}
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {menuItems.map((menuItem, index) => (
          <ListItemButton onClick={() => handleMenuClick(menuItem)} key={index}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={menuItem.name} />
          </ListItemButton>
        ))}
        <ListItemButton onClick={handleSignout}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItemButton>
        <Divider />
        <ListItemButton>
          <img
            src={logo}
            alt="Makkah One"
            style={{
              margin: '30px auto auto auto',
              height: '60px',
              width: '180px',
            }}
          />
        </ListItemButton>
      </List>
    </DrawerStyled>
  );
}
