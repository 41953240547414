import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import Paper from '@mui/material/Paper';
import Title from './Title';

const defaultLabelStyle = {
  fontSize: '10px',
  fontFamily: 'sans-serif',
};

export default function InfluencerCountryDemographic({ demographic }) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: 400,
      }}
    >
      <Title>Ethnicity Enagement Rate</Title>
      <PieChart
        label={({ dataEntry }) => dataEntry.value}
        labelStyle={{
          ...defaultLabelStyle,
        }}
        data={[
          { title: 'One', value: 30, color: '#E38627' },
          { title: 'Two', value: 15, color: '#C13C37' },
          { title: 'Three', value: 20, color: '#6A2135' },
        ]}
        lineWidth={20}
        paddingAngle={18}
        rounded
        labelPosition={60}
        animate
      />
    </Paper>
  );
}
