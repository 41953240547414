import { Button, Grid } from '@mui/material';
import React from 'react';

export default function PromotionPostBotStep({ formState, campaign, next }) {
  const [usertag, setUserTag] = React.useState(campaign.instagram);
  const [caption, setCaption] = React.useState(formState.caption);
  const [hashtags, setHashtags] = React.useState(campaign.tags);

  const handleSubmit = async () => {
    try {
      await fetch(
        `http://127.0.0.1:5000/post_story?image=${encodeURIComponent(
          campaign.campaignImage
        )}&usertag=${usertag}&caption=${caption}`,
        {
          method: 'GET', // *GET, POST, PUT, DELETE, etc.
          mode: 'no-cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        }
      );
      next();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <input
          type="text"
          value={usertag}
          onChange={(e) => setUserTag(e.target.value)}
          placeholder="@usertag"
        />
      </Grid>
      <Grid item>
        <input
          type="text"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          placeholder="caption"
        />
      </Grid>
      <Grid item>
        <input
          type="text"
          value={hashtags}
          onChange={(e) => setHashtags(e.target.value)}
          placeholder="#hashtag, #hahstag"
        />
      </Grid>
      <Grid item>
        <Button onClick={handleSubmit}>Submit Post</Button>
      </Grid>
    </Grid>
  );
}
