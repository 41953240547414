import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function PromotionPlatformSelectStep({ handleClickNext }) {
  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <IconButton
        sx={{ flexDirection: 'column', marginBottom: 7, marginTop: 10 }}
        onClick={handleClickNext}
      >
        <img
          alt="instagram"
          style={{
            width: '130px',
            paddingBottom: 20,
          }}
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/1200px-Instagram_icon.png?20200512141346"
        />
        <Typography>Instagram</Typography>
      </IconButton>
      <IconButton disabled sx={{ flexDirection: 'column' }}>
        <FacebookIcon sx={{ fontSize: '160px' }} />
        <Typography>Facebook</Typography>
      </IconButton>
    </Box>
  );
}
