import React from 'react';
import { Checkbox } from '@mui/material';

export default function TermsOfAgreementStep({ callBack }) {
  return (
    <div
      style={{
        maxHeight: '500px',
        overflowY: 'scroll',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'lightgray',
        padding: '1.5rem'
      }}
    >
      Terms of service Please read the following Terms of service, and
      understand they are a legally binding document. We encourage you to review
      the contents carefully in their entirety and to seek legal counsel if you
      have any concerns. Please note that your use of our WEBSITE constitutes
      your acceptance of these Terms of service and your agreement to be bound
      by them. Definitions "MUI" means Material-UI SAS, a legal entity located
      at 128 Rue La Boétie 75008 Paris, France. "WEBSITE" means the service and
      https://mui.com/store/ and related websites and services. "CONTENT" means
      source code, images, vectors, photos, animations, videos, or other media
      that is available for purchase on the WEBSITE. Overview MUI Store operates
      a global content licensing platform. MUI Store's customers pay a fee to
      license from its platform CONTENT submitted by contributors. Contributors
      get paid a commission every time their CONTENT is licensed. Use of this
      site AS AN ABSOLUTE CONDITION OF YOUR USE OF THE WEBSITE, PRODUCTS, AND/OR
      SERVICES YOU AGREE TO BE BOUND BY THIS AGREEMENT. THIS AGREEMENT IS
      BETWEEN MUI, YOU, YOUR COMPANY, ORGANIZATION, AND YOUR CLIENT(S) IF USING
      THIS WEBSITE ON BEHALF OF YOUR CLIENT(S). WE RESERVE THE RIGHT TO UPDATE
      AND/OR CHANGE THIS AGREEMENT AT ANY TIME AND FOR ANY REASON WITHOUT
      FURTHER WRITTEN NOTICE. BY ACCESSING ANY SERVICE OR PART OF THE WEBSITE
      YOU AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. Ownership This
      WEBSITE is owned and operated by MUI. All source code, images, video,
      audio, text, code, and WEBSITE-related material, is owned by MUI and/or
      its affiliates and is protected by international copyright laws. Any
      unauthorized use or possession of this material is strictly prohibited.
      Authorized use The CONTENT downloaded by you, your company, organization,
      or on behalf of your client(s) is licensed by MUI according to the license
      agreement located at https://mui.com/store/license/. The use of the
      CONTENT or any intellectual property rights therein is dependent on your
      acceptance of these Terms and Conditions and the license agreement listed
      above. Accounts Before you upload or download any CONTENT from MUI you
      need to first register for an account. By registering for an account you
      warrant that you are at least 18 years old, and are fully authorized to
      enter into, and agree to be bound by, these terms and conditions and all
      applicable related agreements. You further agree to provide true and
      accurate information and to create a user name and password for your
      account that is secure, using reasonable steps to prevent unauthorized
      persons or organizations from accessing your account information. If you
      detect that your account has been compromised, you are to notify MUI
      immediately. You may be liable for unauthorized activity, downloads,
      and/or payments. MUI reserves the right to reject your account
      application. It is strictly prohibited to use a MUI account and/or WEBSITE
      to disrupt or destroy MUI property. You may request to close or disable
      your account at any time and MUI will use reasonable time and efforts to
      verify and execute the request. MUI reserves the right to keep copies of
      all account activity archived on our servers. Disabled and closed accounts
      will only be accessible to MUI staff and agents. Parents and Guardians of
      minors under the age of 18 may register an account on MUI on behalf of
      their child/minor if they agree to monitor all activity and assume all
      liability including but not limited to sales, downloads, copyright
      infringement, and applicable taxes. Children under the age of 18 may not
      use the WEBSITE unsupervised by a parent or legal guardian. If you believe
      a child or minor in your care has created an unauthorized account on the
      WEBSITE please contact support@mui.com to have the account removed. MUI
      reserves the right to close, delete, and/or disable your access to the
      website and account for any reason. If MUI terminates your account, you
      must first acquire written approval before establishing another account.
      Information collected MUI may collect information related to your use of
      our website and service, either provided directly by you, or collected via
      technologies such as cookies, tags, scripts, etc. and transfer this
      information to third-party platforms acting as agents on our behalf. We
      use this information to collect statistical data and improve our service
      to users. MUI will collect, use, and implement industry best practices to
      protect your information according to our privacy policy located at
      https://mui.com/store/privacy/. Under lawful requests, MUI may be required
      to share personal data to local law enforcement or government authorities.
      Media content MUI respects the intellectual property rights of all
      artists. All CONTENT provided to MUI is protected by copyright laws and
      remain with the copyright holder unless such media is proven to be within
      the public domain. Licensing MUI issues ROYALTY-FREE licenses on
      conditions outlined in the License agreement located at
      https://mui.com/store/license/. Royalty-Free generally means that you pay
      only once to use the CONTENT for the duration of protection of the
      licensed intellectual property rights, with very few restrictions, and no
      additional fees. Fraud protection In an effort to further protect our
      contributors' rights, we ask that you not serve any CONTENT online
      unminified, or with source maps enabled. Limitations You agree to not
      violate any laws or engage in conduct that infringes on the rights of MUI
      or any third party. You agree to not engage in any activity that would
      deny users and MUI access to the WEBSITE. Warranties contributors make The
      contributor of any CONTENT you buy warrants to you that: The CONTENT is of
      acceptable quality and is fit for the purpose for which it is 'sold'. The
      CONTENT matches the description given on the CONTENT preview page, as well
      as any CONTENT preview. They will honor any express warranties given to
      you that are not contained in these terms. They have the rights necessary
      to license that CONTENT on the terms of applicable license. Your use of
      that CONTENT in accordance with the terms of the applicable license does
      not infringe the intellectual property rights of someone else. The CONTENT
      and its description are not false, inaccurate, misleading, fraudulent,
      unlawful or defamatory. The CONTENT and its description do not violate any
      applicable law or regulation (including those governing export control,
      consumer protection, unfair competition, criminal law, pornography,
      anti-discrimination, trade practices or fair trading). The CONTENT does
      not contain viruses or other computer codes, files or programs which are
      designed to limit or destroy the functionality of other computer software
      or hardware. They will provide you with the services as outlined in the
      CONTENT support policy. They will process your data in accordance with
      applicable privacy laws and data protection regulations. Refunds Refunds
      must follow the Customer refund policy. Except for the Customer refund
      policy, or as required by law, MUI shall be under no obligation to issue
      refunds. If it's determined that you are entitled to a refund of all or
      part of the fees you paid, such refund shall be made using the payment
      method originally used by you to make your purchase. European Union right
      of withdrawal By downloading any CONTENT you consent to begin the
      performance of the service, and relinquish the right of withdrawal. If you
      reside in the European Union and you cancel your order within fourteen
      (14) days of making payment to MUI, and provided that you have not yet
      downloaded any CONTENT, MUI will refund the payment made by you in
      connection with such canceled order. To cancel your order, please contact
      Customer Service via support@mui.com. Trademarks and Intellectual Property
      MUI values the intellectual property of its trademarks, logo, service
      marks, trade names, Internet domain names, or other intellectual property
      claimed now or in the future. Nothing in this agreement grants to you any
      rights to use MUI intellectual property without written consent from a MUI
      corporate officer. You agree to not use the MUI trademarks in any manner
      that might put MUI in a bad light or otherwise disparage MUI. You agree to
      not challenge the validity of the MUI Trademarks and/or intellectual
      property. You agree to not use the MUI name, misspellings of the name, or
      any variant of the MUI name as domain name or social media account. You
      may not copy or imitate the WEBSITE including the page headers, graphics,
      layout, buttons, icons, scripts or other trade dress without the written
      consent of a MUI corporate officer. Infringement Claims MUI respects the
      legitimate intellectual property rights of all artists. If you believe
      your copyright or intellectual property rights are being violated by
      CONTENT provided by MUI, you may notify MUI at legal@mui.com and we will
      act accordingly. Warranties we make Your use of the WEBSITE is at your own
      risk. The WEBSITE is provided by MUI under these terms of use "as is"
      without warranty of any kind, either express, implied, statutory or
      otherwise. MUI expressly disclaims any and all warranties of any kind,
      whether express or implied, to each and any service available from the
      WEBSITE, including, but not limited to the implied warranties of
      merchantability, fitness for a particular purpose, non-infringement, and
      any other warranty that might arise under any law. Without limiting the
      foregoing, MUI makes no warranty that: (i) the WEBSITE will meet your
      requirements; (ii) access to the WEBSITE and service will be
      uninterrupted; (iii) the quality of the WEBSITE will meet your
      expectations; and (iv) any errors or defects in the site, services or
      materials will be corrected. MUI makes no representations or warranties
      that the WEBSITE will be permitted in your jurisdiction, that any of your
      CONTENT submitted by you will be available through the WEBSITE or will be
      stored by the WEBSITE, that the WEBSITE will meet your needs, or that MUI
      will continue to support any particular feature of the WEBSITE. To the
      extent any disclaimer or limitation of liability does not apply, all
      applicable express, implied, and statutory warranties will be limited in
      duration to a period of thirty (30) days after the date on which you first
      used the WEBSITE, and no warranties shall apply after such period.
      Sensitive material MUI does not accept abusive, pornographic, or illegal
      media. However, some approved CONTENT files on the WEBSITE may contain
      offensive, sexually suggestive, and otherwise sensitive material.
      Indemnification You hereby agree to indemnify MUI and its officers,
      directors, employees, agents, and representatives from each and every
      demand, claim, loss, liability, or damage of any kind, including actual
      attorney's fees, whether in tort or contract, that it or any of them may
      incur by reason of, or arising out of, any claim which is made by any
      third party with respect to any breach or violation of this EULA by you or
      any claims based on the CONTENT, including without limitation any claims
      asserted by your end-user customers. Jurisdiction These Terms of service
      shall be governed by and shall be construed in accordance with French law,
      without respect to its conflicts of laws principles. Mediation When
      contracting as a consumer, all actions, controversies and disputes arising
      from or relating to these terms of use may be heard and decided by a
      mediator as provided by Article L.612-1 of the French Consumer Code.
      Miscellaneous In the event that these terms and conditions shall create a
      conflict with any license agreement obtained from MUI, the terms of such
      license agreement shall prevail. Language You agree and understand that
      MUI may provide a translation of these Terms of service and other legal
      agreements into additional languages for your convenience only and the
      English version of these agreements shall govern your relationship with
      MUI. If there is a discrepancy between the English version of the
      agreement and the translated version the English version shall prevail.
      Changes about terms If we change our terms of use we will post those
      changes on this page.
      <div
        style={{
          display: 'block',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <h5 style={{ marginRight: '10px' }}>I am agree to the terms and conditions</h5>
        <Checkbox
          defaultChecked={false}
          size="medium"
          onChange={(e) => {
            callBack()
          }}
        />
      </div>
    </div>
  );
}
