import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Title from './Title';
import LoadingBar from './LoadingBar';
import useUsers from '../hooks/useUsers.js';

// Generate Order Data
function createData(id, date, name, email) {
  return { id, date, name, email };
}

const rows = [createData(0, '06/12/2001', 'nasir@gmail.com')];

export default function AdminUsersList() {
  const { loading, items } = useUsers(4);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingBar />;
  }
  return (
    <>
      <Title>Customers</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Join Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Email</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Phone</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{moment(row.created_at).format('LLL')}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row.phone}</TableCell>
              <TableCell align="right">
                <Button
                  fullWidth
                  onClick={() => navigate(row.id)}
                  variant="contained"
                >
                  View Customer
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
