import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PromotionDialogue from './PromotionDialogue';
import LoadingBar from './LoadingBar';

import useUser from '../hooks/useUser';
import useCampaigns from '../hooks/useCampaigns';
import usePromotions from '../hooks/usePromotions';

export default function InfluencerPromotions() {
  const user = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedCampaign, setCampaign] = React.useState(null);

  const { loading: loadingPromotions, items } = usePromotions(user);
  const { loading: loadingCampaigns, items: campaigns } = useCampaigns({
    ...user,
    role: 1,
  });

  const handleClickOpen = (item) => {
    setOpen(true);
    setCampaign(item);
  };

  const handleClose = () => {
    setOpen(false);
    setCampaign(null);
  };

  if (loadingCampaigns || loadingPromotions) {
    return <LoadingBar />;
  }

  return (
    <>
      {selectedCampaign && (
        <PromotionDialogue
          open={open}
          handleClose={handleClose}
          campaign={selectedCampaign}
        />
      )}
      <Grid container spacing="20">
        {campaigns.map((row, key) => (
          <Grid item xs={4} key={key}>
            <Card>
              <CardMedia component="img" height="140" src={row.campaignImage} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {row.campaignName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {row.campaignUrl}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  onClick={() => handleClickOpen(row)}
                  size="small"
                >
                  Promote Campaign
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
