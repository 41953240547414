import * as React from 'react';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import Title from './Title';

export default function VendorRecentSales({ conversions }) {
  return (
    <>
      <Title>Latest Conversions</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Id</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="right">
              Sale Amount
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Campaign</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conversions.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {moment(row.created_at).format('LL @ hh:mm A')}
              </TableCell>
              <TableCell>{row.line_items.map((item) => item.name)}</TableCell>
              <TableCell>
                {row.line_items.map((item) => item.product_id)}
              </TableCell>
              <TableCell align="right">
                {`$${row.total_price}`} {row.currency}
              </TableCell>
              <TableCell>
                <Link to={`/vendor/campaigns/${row.mcid}`}>View Campaign</Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
