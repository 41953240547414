import * as React from 'react';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StarIcon from '@mui/icons-material/Star';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

import { Box, Button, Typography } from '@mui/material';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import CampaignTypeStep from '../../components/CampaignTypeStep';
import CampaignOfferStep from '../../components/CampaignOfferStep';
import CampaignAudienceStep from '../../components/CampaignAudienceStep';
import CampaignPixelStep from '../../components/CampaignPixelStep';
import CampaignReviewStep from '../../components/CampaignReviewStep';

import Title from '../../components/Title';
import { db } from '../../firebase/client';
import useUser from '../../hooks/useUser';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgba(233, 178, 148, 33) 0%,#E0946A 50%,rgba(68, 65, 63, 1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgba(233, 178, 148, 33) 0%,#E0946A 50%,rgba(68, 65, 63, 1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgba(233, 178, 148, 33) 0%, #E0946A 50%, rgba(68, 65, 63, 1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgba(233, 178, 148, 33) 0%, #E0946A 50%, rgba(68, 65, 63, 1) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;
  const icons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
    3: <GroupAddIcon />,
    4: <StarIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Select campaign type',
  'Create an offer',
  'Select Audience',
  'Review Campaign',
];

function getStepContent(step, formState, setFormState) {
  switch (step) {
    case 0:
      return (
        <CampaignTypeStep formState={formState} setFormState={setFormState} />
      );
    case 1:
      return (
        <CampaignOfferStep formState={formState} setFormState={setFormState} />
      );
    case 2:
      return (
        <CampaignAudienceStep
          formState={formState}
          setFormState={setFormState}
        />
      );
    case 3:
      return <CampaignReviewStep formState={formState} />;
    case 4:
      return step;
    default:
      throw new Error('Unknown step');
  }
}

export default function CreateCampaign() {
  const user = useUser();
  const navigate = useNavigate();
  const [formState, setFormState] = React.useState({
    campaignName: null,
    campaignUrl: null,
    campaignType: 1,
    campaignImage: null,
    offerType: null,
    pointAmount: 5,
    inclusions: [0, 1, 2, 3],
    exclusions: [4, 5, 6, 7],
    langinclusions: ['English', 'French', 'Arabic', 'Spanish'],
    langexclusions: [],
    startDate: null,
    endDate: null,
    otherExclusions: null,
    minAge: 18,
    maxAge: 65,
    gender: 'all',
    clicks: 0,
    impressions: 0,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const createCampaign = async () => {
    const campRef = collection(db, 'campaigns');
    const campDoc = await addDoc(campRef, {
      ...formState,
      uid: user.uid,
      status: 'pending',
      created_at: Date.now(),
      companyName: user.companyName,
      companyLogo: user.photoURL,
      companyWebsite: user.companyWebsite,
      companyDesc: user.companyDescription,
      category: user.category,
    });

    navigate(`/vendor/campaigns/${campDoc.id}`);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Title>Create new campaign</Title>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          sx={{ my: 5 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ px: 6, py: 10, display: 'flex', flexDirection: 'column' }}>
          <div>
            {allStepsCompleted() ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Your campaign has been successfully submitted, it is currently
                  being reviewed by our team for approval.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </>
            ) : (
              <>
                {getStepContent(activeStep, formState, setFormState)}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                      Back
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    onClick={() =>
                      activeStep === steps.length - 1
                        ? createCampaign()
                        : handleNext()
                    }
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1
                      ? 'Create Campaign'
                      : 'Next'}
                  </Button>
                </Box>
              </>
            )}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
