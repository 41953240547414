import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { doc, getDoc } from 'firebase/firestore';
import Title from './Title';
import LoadingBar from './LoadingBar';
import useUsers from '../hooks/useUsers.js';
import useCampaigns from '../hooks/useCampaigns';
import useUser from '../hooks/useUser';
import { auth, db } from '../firebase/client';

// Generate Order Data
function createData(id, date, name, email) {
  return { id, date, name, email };
}

const rows = [createData(0, '06/12/2001', 'nasir@gmail.com')];

export default function UserActiveOffers({ userId }) {
  const user = useUser(userId);
  const { currentUser } = auth;
  const navigate = useNavigate();

  const [offers, setOffers] = React.useState([]);
  const [loadingOffers, setLoadingOffers] = React.useState(true);
  const { loading, items } = useCampaigns({
    ...currentUser,
    role: 1,
  });

  React.useEffect(() => {
    async function getOffers() {
      if (user && user.activeCLO && user.activeCLO.length > 0) {
        const docs = [];

        for (const campaignId of user.activeCLO) {
          try {
            const campaignRef = doc(db, 'campaigns', campaignId);
            const campaignDoc = await getDoc(campaignRef);
            docs.push({ ...campaignDoc.data() });
          } catch (error) {
            console.log(error);
          }
        }

        setOffers(docs);
        setLoadingOffers(false);
      }
    }

    if (loadingOffers && !loading && user) {
      getOffers();
    }
  }, [loading, loadingOffers, user, offers]);

  return (
    <>
      <Title>Active Offers</Title>
      {!loading ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date Activated</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Campaign Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Campaign URL</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Point Per $1</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Redeemed</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{moment(row.created_at).format('LLL')}</TableCell>
                <TableCell>{row.campaignName}</TableCell>
                <TableCell>{row.campaignUrl}</TableCell>
                <TableCell>{row.pointAmount}/$1</TableCell>
                <TableCell>Pending</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <LoadingBar />
      )}
    </>
  );
}
