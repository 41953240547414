import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { auth } from '../firebase/client';

export default function AccountInfoStep({ formState, setFormState }) {
  const user = auth.currentUser;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.firstName}
          onChange={(e) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
          required
          id="firstName"
          name="firstName"
          label="First name"
          fullWidth
          autoComplete="given-name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          value={formState.lastName}
          onChange={(e) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
          required
          id="lastName"
          name="lastName"
          label="Last name"
          fullWidth
          autoComplete="family-name"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.phone}
          onChange={(e) =>
            setFormState({
              ...formState,
              phone: e.target.value,
            })
          }
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={formState.email}
          onChange={(e) =>
            setFormState({
              ...formState,
              email: e.target.value,
            })
          }
          defaultValue={user.email}
          disabled
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
        />
      </Grid>
    </Grid>
  );
}
