import React, { useEffect, useState } from 'react';
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase/client';
import VendorCampaigns from '../../components/VendorCampaigns';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Title from '../../components/Title';
import LoadingBar from '../../components/LoadingBar';
import useUser from '../../hooks/useUser';
import useCampaigns from '../../hooks/useCampaigns';

export default function CampaignsList() {
  const user = useUser();
  const { loading, items } = useCampaigns(user);

  if (loading) {
    return <LoadingBar />;
  }

  console.log(items);

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Title>Campaigns</Title>
      <VendorCampaigns showVendorName items={items} />
    </Paper>
  );
}
