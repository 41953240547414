import React from 'react';
import { Container } from '@mui/material';
import { Box } from '@mui/material';

export default function ForgotPassword() {
  return  ( 
    <Container component='main' maxWidth = 'xs'>
         <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
         >
 {/* <img
          src={logo}
          alt="Makkah One"
          style={{ height: '70px', marginBottom: 20 }}
        /> */}
         </Box>
    </Container>
  )
}
