import { Box, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import InstagramCard from './InstagramCard';
import InstagramStory from './InstagramStory';

export default function PromotionPostStyleStep({
  campaign,
  formState,
  setFormState,
  next,
}) {
  const onSelect = (type) => {
    setFormState({
      ...formState,
      type,
    });
    next();
  };

  return (
    <Grid container spacing="20">
      <Grid item xs={6}>
        <InstagramCard
          onSelect={onSelect}
          type={formState.type}
          formState={formState}
          setFormState={setFormState}
          campaign={campaign}
        />
      </Grid>
      <Grid item xs={6}>
        <InstagramStory
          onSelect={onSelect}
          type={formState.type}
          campaign={campaign}
        />
      </Grid>
    </Grid>
  );
}
