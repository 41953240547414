import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Generate Order Data
function createData(id, date, name, status, amount) {
  return { id, date, name, status, amount };
}

const rows = [
  // createData(0, '16 Mar, 2019', 'Spring Campaign', 'pending', 314.21),
];

export default function CampaignSales() {
  const navigate = useNavigate();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Campaign Name</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
          <TableCell sx={{ fontWeight: 'bold' }} align="right">
            Sale Amount
          </TableCell>
          <TableCell align="right" />
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.id}>
            <TableCell>{row.date}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.status}</TableCell>
            <TableCell align="right">{`$${row.amount}`}</TableCell>
            <TableCell align="right">
              <Button onClick={() => navigate(`${row.id}`)}>
                View Invoice
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
