import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import AppBar from '../components/AppBar';
import Drawer from '../components/Drawer';
import useUser from '../hooks/useUser';

export default function DashboardLayout({ userRole }) {
  const user = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  console.log(user)

  if (!user) {
    navigate('/login');
  }

  return (
    <Box sx={{ display: 'flex', backgroundColor: 'red' }}>
      <AppBar
        position="absolute"
        open={open}
        toggleDrawer={toggleDrawer}
        userRole={userRole}
      />
      <Drawer
        userRole={userRole}
        variant="permanent"
        open={open}
        toggleDrawer={toggleDrawer}
      />
      <Outlet />
    </Box>
  );
}
