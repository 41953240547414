import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Title from './Title';
import LoadingBar from './LoadingBar';
import usePixel from '../hooks/usePixel';
import useUsers from '../hooks/useUsers';

// Generate Order Data
function createData(id, date, name, email) {
  return { id, date, name, email };
}

const rows = [createData(0, '06/12/2001', 'nasir@gmail.com')];

export default function UserPixelEvents({ campaignId }) {
  const { loading, items } = usePixel();
  const { loading: loadingUsers, items: users } = useUsers(4);
  const navigate = useNavigate();

  if (loading) {
    return <LoadingBar />;
  }
  if (loadingUsers) {
    return <LoadingBar />;
  }

  return (
    <>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 5,
        }}
      >
        <Title>Traffic Events</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Page</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Browser</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Device</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter((item) => item.mcid === campaignId)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{moment.unix(row.ts).format('LLL')}</TableCell>
                  <TableCell>{row.canon_url}</TableCell>
                  <TableCell>{row.ev}</TableCell>
                  <TableCell>{row.bn}</TableCell>
                  <TableCell>{row.md ? 'Mobile' : 'Desktop'}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 5,
        }}
      >
        <Title>Shopping Events</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Product Price</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Product Url</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>User Age</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>User Gender</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>User City</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>User Country</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter(
                (item) => item.mcid === campaignId && item.ev === 'view_product'
              )
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{moment.unix(row.ts).format('LLL')}</TableCell>
                  <TableCell>{JSON.parse(row.ed).productName}</TableCell>
                  <TableCell>${JSON.parse(row.ed).productPrice}</TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      href={`${row.origin}${JSON.parse(row.ed).productURL}`}
                      rel="noreferrer"
                    >
                      {JSON.parse(row.ed).productURL}
                    </a>
                  </TableCell>
                  <TableCell>
                    {moment().diff(
                      moment(
                        users.filter((user) => user.uid === row.m1cuid)[0].dob,
                        'mm-dd-yyyy'
                      ),
                      'years'
                    )}{' '}
                    years old
                  </TableCell>
                  <TableCell>
                    {users.filter((user) => user.uid === row.m1cuid)[0].gender}
                  </TableCell>
                  <TableCell>Mississauga, ON</TableCell>
                  <TableCell>Canada</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
