import React from 'react';

import Fab from '@mui/material/Fab';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import AdminVendorsList from '../../components/AdminVendorsList';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Title from '../../components/Title';

export default function CampaignsList() {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();

  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <AdminVendorsList />
    </Paper>
  );
}
