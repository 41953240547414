import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/ShoppingBag';
import GameIcon from '@mui/icons-material/Gamepad';
import MobileIcon from '@mui/icons-material/PhoneAndroid';
import { Button, Grid, TextField, Typography } from '@mui/material';

export default function CampaignTypeStep({ formState, setFormState }) {
  const [campaignType, setCampaignType] = useState(null);
  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} sx={{ textAlign: 'center' }} mb={3}>
        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
          Enter Campaign name
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'center' }} mb={3}>
        <TextField
          onChange={(event) =>
            setFormState({
              ...formState,
              campaignName: event.target.value,
            })
          }
          required
          fullWidth
          id="campaignName"
          label="ex: Summer Special"
          value={formState.campaignName}
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }} mb={3}>
        <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
          Select Campaign Type
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        p={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Button
          onClick={() => setCampaignType(0)}
          variant={campaignType === 0 ? 'contained' : 'outlined'}
          sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
        >
          <AddIcon />
          <Typography variant="button" mt={1}>
            Shopping
          </Typography>
        </Button>
      </Grid>
      <Grid
        item
        xs={3}
        p={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Button
          variant="outlined"
          disabled
          sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
        >
          <GameIcon />
          <Typography variant="button" mt={1}>
            Game
          </Typography>
        </Button>
      </Grid>
      <Grid
        item
        xs={3}
        p={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Button
          variant="outlined"
          disabled
          sx={{ flex: 1, height: '150px', flexDirection: 'column' }}
        >
          <MobileIcon />
          <Typography variant="button" mt={1}>
            App
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
