import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import { collection, doc, getDoc } from 'firebase/firestore';
import ProtectedRoute from './components/ProtectedRoute';

import AdminHome from './pages/admin/Home';
import AdminVendor from './pages/admin/Vendor';
import AdminVendors from './pages/admin/Vendors';
import AdminVendorsList from './pages/admin/VendorsList';
import AdminCampaign from './pages/admin/Campaign';
import AdminCampaigns from './pages/admin/Campaigns';
import AdminCampaignsList from './pages/admin/CampaignsList';
import AdminAnalytics from './pages/admin/Analytics';

import AdminUser from './pages/admin/User';
import AdminUsers from './pages/admin/Users';
import AdminAccount from './pages/admin/Account';
import AdminUsersList from './pages/admin/UsersList';

import VendorHome from './pages/vendor/Home';
import VendorAccount from './pages/vendor/Account';

import VendorCampaign from './pages/vendor/Campaign';
import VendorCampaigns from './pages/vendor/Campaigns';
import VendorCampaignsList from './pages/vendor/CampaignsList';
import VendorCreateCampaign from './pages/vendor/CreateCampaign';
import VendorAnalytics from './pages/vendor/Analytics';

import VendorInvoice from './pages/vendor/Invoice';
import VendorInvoices from './pages/vendor/Invoices';
import VendorInvoicesList from './pages/vendor/InvoicesList';

import InfluencerHome from './pages/influencer/Home';
import InfluencerPromotions from './pages/influencer/Promotions';
import InfluencerPromotion from './pages/influencer/Promotion';
import InfluencerPromotionsList from './pages/influencer/PromotionsList';

import Login from './pages/shared/Login/Login';
import ForgotPassword from './pages/shared/Login/ForgotPassword';
import CreateAccount from './pages/shared/Registration/CreateAccount';
import VerifyAccount from './pages/shared/Registration/VerifyAccount';
import CompleteProfile from './pages/shared/Registration/CompleteProfile';

import DashboardLayout from './layout/DashboardLayout';
import RegistrationLayout from './layout/RegistrationLayout';
import { auth, db } from './firebase/client';
import LoadingBar from './components/LoadingBar';
import useUser from './hooks/useUser';
import UmmrahLottery from './pages/admin/UmmrahLottery';

const App = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const logedInUser = useUser()

  useEffect(
    () =>
      auth.onAuthStateChanged(async (data) => {
        if (data) {
          const userRef = doc(collection(db, 'users'), data.uid);
          const getUser = await getDoc(userRef);
          if (getUser.exists()) {
            setUser({
              ...data,
              ...getUser.data(),
            });
          }
        } else {
          setUser(null);
        }
        setLoading(false);
      }),
    []
  );

  if (loading) {
    return <LoadingBar />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/** Administrator Routes */}
        <Route
          path="admin"
          element={<DashboardLayout userRole={1} />}>
          <Route index path="home" element={<AdminHome />} />
          <Route index path="lottery" element={<UmmrahLottery/>} />
          <Route index path="analytics" element={<AdminAnalytics />} />
          <Route path="vendors" element={<AdminVendors />}>
            <Route path=":id" element={<AdminVendor />} />
            <Route index element={<AdminVendorsList />} />
          </Route>
          <Route path="campaigns" element={<AdminCampaigns />}>
            <Route path=":id" element={<AdminCampaign />} />
            <Route index element={<AdminCampaignsList />} />
          </Route>
          <Route path="users" element={<AdminUsers />}>
            <Route path=":id" element={<AdminUser />} />
            <Route index element={<AdminUsersList />} />
          </Route>
          <Route index path="account" element={<AdminAccount />} />
        </Route>
        {/** Merchant/Vendor Routes */}
        <Route path="vendor" element={<DashboardLayout userRole={2} />}>
          <Route index path="home" element={<VendorHome />} />
          <Route index path="analytics" element={<VendorAnalytics />} />
          <Route path="campaigns" element={<VendorCampaigns />}>
            <Route path=":id" element={<VendorCampaign />} />
            <Route path="create" element={<VendorCreateCampaign />} />
            <Route index element={<VendorCampaignsList />} />
          </Route>
          <Route path="invoices" element={<VendorInvoices />}>
            <Route path=":id" element={<VendorInvoice />} />
            <Route index element={<VendorInvoicesList />} />
          </Route>
          <Route index path="account" element={<VendorAccount />} />
        </Route>
        {/** Infeluncer Routes */}
        <Route path="influencer" element={<DashboardLayout userRole={3} />}>
          <Route index path="home" element={<InfluencerHome />} />
          <Route path="promotions" element={<InfluencerPromotions />}>
            <Route path=":id" element={<InfluencerPromotion />} />
            <Route index element={<InfluencerPromotionsList />} />
          </Route>
        </Route>
        {/** Authentication Routes */}
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="register" element={<RegistrationLayout />}>
          <Route index element={<CreateAccount />} />
          <Route path="verify-account" element={<VerifyAccount />} />
          <Route path="complete-profile" element={<CompleteProfile />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
