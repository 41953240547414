import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';

import { collection, onSnapshot, query, where } from 'firebase/firestore';

import InfluencerMetrics from '../../components/InfluencerMetrics';
import InfluencerRecentSales from '../../components/InfluencerRecentSales';
import InfluencerRevenueChart from '../../components/InfluencerRevenueChart';
import InfluencerPromotions from '../../components/InfluencerPromotions';
import InfluencerAgeDemographic from '../../components/InfluencerAgeDemographic';
import InfluencerCountryDemographic from '../../components/InfluencerCountryDemographic';

import useUser from '../../hooks/useUser';
import useCampaigns from '../../hooks/useCampaigns';
import LoadingBar from '../../components/LoadingBar';
import { auth, db } from '../../firebase/client';

export default function Home() {
  const user = useUser();
  const { loading, items } = useCampaigns(user);
  const currentGuy = auth.currentUser;
  const [conversions, setConversions] = React.useState([]);

  React.useEffect(() => {
    async function getConversions() {
      const conversionsRef = collection(db, 'conversions');
      const conversionsQuery = query(
        conversionsRef,
        where('vendorId', '==', currentGuy.uid)
      );

      const unsubscribe = onSnapshot(
        conversionsQuery,
        async (querySnapshot) => {
          const docs = [];
          querySnapshot.forEach(async (document) => {
            docs.push({ ...document.data() });
          });
          setConversions(docs);
        }
      );
      return unsubscribe;
    }
    getConversions();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {user && !loading ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InfluencerMetrics items={[]} conversions={[]} />
            </Grid>

            <Grid item xs={6}>
              <InfluencerAgeDemographic demographic={[]} />
            </Grid>
            <Grid item xs={6}>
              <InfluencerCountryDemographic demographic={[]} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <InfluencerRevenueChart conversions={[]} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <InfluencerPromotions promotions={[]} />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                  >
                    <InfluencerRecentSales conversions={[]} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <LoadingBar />
        )}
      </Container>
    </Box>
  );
}
