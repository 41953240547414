/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import DeleteIcon from '@mui/icons-material/Close';

import { Box, Button, Grid, Stack, TextField } from '@mui/material';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class AudienceLocations extends Component {
  static defaultProps = {
    center: {
      lat: 43.653225,
      lng: -79.383186,
    },
    city: '',
    zoom: 10,
  };

  constructor() {
    super();
    this.state = {
      city: '',
      cities: [],
      polygons: [],
      map: null,
      maps: null,
      disable: '',
    };
  }

  async getCityPolygon(city) {
    try {
      const data = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${city}&format=json&polygon_geojson=1&addressdetails=1`
      );
      const json = await data.json();
      return json;
    } catch (error) {
      throw new Error(error);
    }
  }

  render() {
    const { cities, city, maps, map } = this.state;
    const initializeMap = async (mmap, mmaps) => {
      this.setState({
        map: mmap,
        maps: mmaps,
      });
    };

    const setNewPolygon = async (e) => {
      e.preventDefault();
      try {
        const data = await this.getCityPolygon(city);
        if (data.length === 0) {
          alert('No City found');
          return;
        }

        const { geojson } = data[0];
        const { coordinates } = geojson;

        const triangleCoords = coordinates[0].map(([a, b]) => ({
          lat: b,
          lng: a,
        }));

        const newCity = {
          city,
          poly: triangleCoords,
        };

        this.setState(
          (state) => ({
            cities: [...state.cities, newCity],
            city: '',
          }),
          () => {
            this.props.setCities(this.state.cities);
          }
        );

        const randomColor = Math.floor(Math.random() * 16777215).toString(16);

        const bermudaTriangle = new maps.Polygon({
          paths: triangleCoords,
          strokeColor: `#${randomColor}`,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: `#${randomColor}`,
          fillOpacity: 0.35,
        });

        bermudaTriangle.setMap(map);
        maps.event.addListener(bermudaTriangle, 'click', function () {
          this.setMap(null);
        });
      } catch (error) {
        alert('Invalid city or region');
      }
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <Stack
            component="form"
            spacing={2}
            direction="row"
            mb={3}
            onSubmit={setNewPolygon}
          >
            <TextField
              required
              className="mt-4 mb-3"
              type="text"
              placeholder="enter city name"
              value={city}
              fullWidth
              onChange={(e) => this.setState({ city: e.target.value })}
            />
            <Button
              variant="contained"
              sx={{ width: '200px' }}
              color="primary"
              type="submit"
            >
              Add Location
            </Button>
          </Stack>
          {/* <Stack direction="row" spacing={2} mb={3}>
            {cities.map((row) => (
              <Button
                variant="outlined"
                onClick={() => removePolygon(row.city)}
              >
                {row.city} <DeleteIcon />
              </Button>
            ))}
          </Stack> */}
        </Grid>
        <Grid item xs={12} mb={5}>
          <Box borderRadius={5} overflow="hidden" height="500px" width="100%">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAJQJbyWiZqciw_M0aGwWCor6vH9IAFBFE',
              }}
              defaultCenter={this.props.center}
              defaultZoom={this.props.zoom}
              onGoogleApiLoaded={({ map, maps }) => initializeMap(map, maps)}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default AudienceLocations;
