import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Title from './Title';
import LoadingBar from './LoadingBar';
import useUsers from '../hooks/useUsers';

export default function AdminVendorsList() {
  const { loading, items } = useUsers(2);
  const navigation = useNavigate();

  if (loading) {
    return <LoadingBar />;
  }

  console.log(items);
  return (
    <>
      <Title>Vendors</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Join Date</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Vendor Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Category</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{moment(row.created_at).format('LL')}</TableCell>
              <TableCell>{row.companyName}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                <Button
                  onClick={() => navigation(row.id)}
                  fullWidth
                  variant="contained"
                >
                  View Account
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
