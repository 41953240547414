import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Title from './Title';

export default function CampaignReviewStep({ formState }) {
  return (
    <>
      <Title>Review Campaign</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Campaign Type:{' '}
          </Typography>
          <Typography variant="body1">Shopping</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Offer Type:{' '}
          </Typography>
          <Typography variant="body1">
            {formState.offerType === 0 && 'Card Linked Offer'}
            {formState.offerType === 1 && 'Online Offer'}
            {formState.offerType === 2 && 'CLO and Online Offer'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Points:{' '}
          </Typography>
          <Typography variant="body1">
            {formState.pointAmount} per $1
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Campaign starts
          </Typography>
          <Typography variant="body1">{formState.startDate}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Campaign ends
          </Typography>
          <Typography variant="body1">{formState.endDate}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Product Inclusions
          </Typography>
          {formState.inclusions.map((item) => (
            <Typography variant="body1">{item}</Typography>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Product Exclusions
          </Typography>
          {formState.exclusions.map((item) => (
            <Typography variant="body1">{item}</Typography>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Other Exclusions
          </Typography>
          <Typography variant="body1">{formState.otherExclusions}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Target Audience</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Age Range
          </Typography>
          <Typography variant="body1">
            Between {formState.minAge} and {formState.maxAge}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Gender
          </Typography>
          <Typography variant="body1">{formState.gender}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Locations
          </Typography>

          <Typography variant="body1">Toronto ON | Mississauga ON</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Language Inclusions
          </Typography>
          {formState.langinclusions.map((lang) => (
            <Typography variant="body1">{lang}</Typography>
          ))}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Language Exclusions
          </Typography>
          {formState.langexclusions.map((lang) => (
            <Typography variant="body1">{lang}</Typography>
          ))}
        </Grid>
      </Grid>
    </>
  );
}
