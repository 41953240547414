import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Divider, Stack, TextField, Typography } from '@mui/material';
import { useLocation, useMatch } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import Title from '../../components/Title';
import CampaignMetrics from '../../components/CampaignMetrics';
import CampaignSales from '../../components/CampaignSales';
import CampaignReach from '../../components/CampaignReach';
import useCampaign from '../../hooks/useCampaign';
import LoadingBar from '../../components/LoadingBar';
import { auth, db } from '../../firebase/client';
import shopfiyWebhook from '../../assets/webhook-shopify.png';

export default function Campaign() {
  const {
    params: { id },
  } = useMatch('/vendor/campaigns/:id');
  const campaign = useCampaign(id);
  const [conversions, setConversions] = useState([]);

  const pixelCode = `<!-- Start MakkahOne Pixel Snippet >;
  <script>
  !function(e,n,t,i,a,s,p){e[i]||((a=e[i]=function(){a.process?a.process.apply(a,arguments):a.queue.push(arguments)}).queue=[],a.t=+new Date,(s=n.createElement(t)).async=1,s.src="https://m1.jannahsfamilyandfriends.com/dist/m1pixel.min.js?t="+864e5*Math.ceil(new Date/864e5),(p=n.getElementsByTagName(t)[0]).parentNode.insertBefore(s,p))}(window,document,"script","m1pix"),m1pix("init","ID-${id}"),m1pix("event","pageload");
  </script>
  <!-- End MakkahOne Pixel Snippet >;`;

  useEffect(() => {
    async function getConversions() {
      const conversionsRef = collection(db, 'conversions');
      const conversionsQuery = query(conversionsRef, where('mcid', '==', id));

      const unsubscribe = onSnapshot(
        conversionsQuery,
        async (querySnapshot) => {

          const docs = [];
          // querySnapshot.docs.forEach(async (document) => {
          //   docs.push({ ...document.data() });
          // });
          setConversions(docs);
        }
      );
      return unsubscribe;
    }
    getConversions();
  }, []);

  if (!campaign) {
    return <LoadingBar />;
  }

  return (
    <>
      <Box
        height="400px"
        width="100%"
        style={{
          backgroundImage: `url('${campaign.campaignImage}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
        mb={2}
      />
      <Title>Summer Special</Title>
      <Divider light sx={{ marginBottom: 2 }} />
      <CampaignMetrics
        totalConversions={conversions.length}
        totalClicks={campaign.clicks}
        totalImpressions={campaign.impressions}
        totalProfit={conversions.reduce(
          (prev, currValue) =>
            parseFloat(prev) + parseFloat(currValue.total_price),
          0
        )}
        totalPoints={conversions.reduce(
          (prev, currValue) =>
            parseFloat(prev) + parseFloat(currValue.makkahPoints),
          0
        )}
      />
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Paper
            sx={{
              marginTop: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CampaignSales items={conversions} />
          </Paper>
          <Paper
            sx={{
              marginTop: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CampaignReach campaign={campaign} />
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper
            sx={{
              marginTop: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              pb: 2,
            }}
          >
            <Title>Campaign Details</Title>
            <Box mb={2}>
              <Typography variant="body1" fontWeight="bold">
                Status
              </Typography>
              <Typography variant="body1">{campaign.status}</Typography>
            </Box>
            <Divider light />
            <Box mb={2}>
              <Typography variant="body1" fontWeight="bold">
                Type
              </Typography>
              <Typography variant="body1">Shopping</Typography>
            </Box>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Offer Type
              </Typography>
              <Typography variant="body1">
                {campaign.campaignType === 0 && 'Card Linked Offer'}
                {campaign.campaignType === 1 && 'Online Offer'}
                {campaign.campaignType === 2 && 'CLO & Online'}
              </Typography>
            </Stack>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Campaign Duration
              </Typography>
              <Typography variant="body1">
                Start Date/Time: {moment(campaign.startDate).format('LL')}
              </Typography>
              <Typography variant="body1">
                End Date/Time: {moment(campaign.endDate).format('LL')}
              </Typography>
            </Stack>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Points
              </Typography>
              <Typography variant="body1">
                {campaign.pointAmount} per $1
              </Typography>
            </Stack>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Inclusions
              </Typography>
              {campaign.inclusions.map((inclusion) => (
                <Typography variant="body1">{inclusion}</Typography>
              ))}
            </Stack>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Exclusions
              </Typography>
              {campaign.exclusions.map((exclusion) => (
                <Typography variant="body1">{exclusion}</Typography>
              ))}
            </Stack>
            <Divider light />
            <Stack direction="column" mb={2} mt={2}>
              <Typography variant="body1" fontWeight="bold">
                Other exclusions
              </Typography>
              <Typography variant="body1">
                {campaign.otherExclusions}
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Paper
        sx={{
          marginTop: 3,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container pb={2}>
          <Grid item xs={12} mb={3}>
            <Title> MakkahOne Pixel</Title>
          </Grid>
          <Grid item xs={12}>
            <Typography fontWeight="bold">
              1. Copy paste the following code inside your head tag
            </Typography>
            <br />
            <TextField multiline rows={5} value={pixelCode} fullWidth />
            <Typography fontWeight="bold" mt={3}>
              2. Shopify order/paid webhook
            </Typography>
            <br />
            <TextField
              value={`https://m1.jannahsfamilyandfriends.com/vendor/payment?muid=${auth.currentUser.uid}&mcid=${id}`}
              fullWidth
            />
            <img
              src={shopfiyWebhook}
              alt="webhook"
              style={{ width: '100%', marginTop: 20 }}
            />
            <Typography fontWeight="bold" mt={3}>
              3. Shopify checkout additional script
            </Typography>
            <br />
            <TextField
              value={`{% if first_time_accessed %}
              <img src="https://m1.jannahsfamilyandfriends.com/tracking/pixel.gif?amount={{ total_price | money_without_currency }}&amp;order-id={{ order_number }}&amp;currency={{ currency }}" height="1" width="1" />
              {% endif %}`}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
