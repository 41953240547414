import * as React from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';

import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { useMatch } from 'react-router-dom';

import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import UserMetrics from '../../components/UserMetrics';

import LoadingBar from '../../components/LoadingBar';
import { db } from '../../firebase/client';
import useUser from '../../hooks/useUser';
import Title from '../../components/Title';
import AccountInfoStep from '../../components/AccountInfoStep';
import AddressInfoStep from '../../components/AddressInfoStep';
import CompanyBrandInfoStep from '../../components/CompanyBrandInfoStep';
import UserActiveOffers from '../../components/UserActiveOffers';
import UserConversionEvents from '../../components/UserConversionEvents';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function User() {
  const {
    params: { id },
  } = useMatch('/admin/users/:id');
  const user = useUser(id);
  const [conversions, setConversions] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState(0);

  const [formState, setFormState] = React.useState({
    firstName: null,
    lastName: null,
    phone: null,
    address1: null,
    address2: null,
    city: null,
    postal: null,
    province: null,
    country: null,
    brandPrimaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    brandSecondaryColor: {
      r: 100,
      g: 30,
      b: 19,
      a: 1,
    },
    companyName: null,
    companyDescription: null,
    companyKeywords: null,
    privacyPolicyUrl: null,
    category: null,
    subcategory: null,
  });

  const changeTab = (event, newTabStep) => {
    setCurrentTab(newTabStep);
  };

  const updateAccStatus = async (accStatus) => {
    try {
      const vendorRef = doc(db, 'users', id);
      await updateDoc(vendorRef, {
        status: accStatus,
      });

      toast('Account status updated');
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    async function getConversions() {
      const conversionsRef = collection(db, 'conversions');
      const conversionsQuery = query(conversionsRef, where('cuid', '==', id));

      const unsubscribe = onSnapshot(
        conversionsQuery,
        async (querySnapshot) => {
          const docs = [];
          querySnapshot.forEach(async (document) => {
            docs.push({ ...document.data() });
          });
          setConversions(docs);
        }
      );
      return unsubscribe;
    }
    getConversions();
  }, []);

  React.useEffect(() => {
    if (user) {
      setFormState({
        email: user.email,
        phone: user.phone,
      });
    }
  }, [user]);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {user && <Title>{user.companyName}</Title>}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab}
          onChange={changeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Account Overview" />
          <Tab label="Account Information" />
          <Tab label="Ummrah Roadmap" />
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={1}>
        {user ? (
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  pb: 8,
                }}
              >
                <Stack justifyContent="center" alignItems="center" mt={5}>
                  <Typography variant="body1" fontWeight="bold" mt={2}>
                    {user.name}
                  </Typography>
                  <Typography variant="body1">{user.gender}</Typography>
                  <Typography variant="body1">{user.dob}</Typography>
                  <Typography variant="body2" mt={1}>
                    {user.status === 'completed' && 'Account Active'}
                  </Typography>
                  {(user.status === 'active' ||
                    user.status === 'completed') && (
                    <Button
                      onClick={() => updateAccStatus('blocked')}
                      style={{ marginTop: 30 }}
                      color="error"
                    >
                      Block User
                    </Button>
                  )}
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 3,
                }}
              >
                <Title>Profile Information</Title>
                <AccountInfoStep
                  formState={formState}
                  setFormState={setFormState}
                />
              </Paper>

              <Paper
                sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  mb: 3,
                }}
              >
                <Title>Billing Address</Title>
                <AddressInfoStep
                  formState={formState}
                  setFormState={setFormState}
                />
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <LoadingBar />
        )}
      </TabPanel>
      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserMetrics userId={id} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <UserConversionEvents userId={id} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <UserActiveOffers userId={id} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* ) : (
          <LoadingBar />
        )} */}
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        asdsdad
      </TabPanel>
    </Container>
  );
}
