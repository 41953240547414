import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Title from '../../components/Title';

import InfluencerPromotions from '../../components/InfluencerPromotions';

export default function PromotionsList() {
  return (
    <>
      <Title>Latest Promotions</Title>
      <InfluencerPromotions />
    </>
  );
}
